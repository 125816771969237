<template>
  <div>
    <v-card
      outlined
      style="
        border-radius: 20px;
        background-color: var(--v-toolbar-lighten1) !important;
      "
    >
      <v-card-text class="my-0 py-0">
        <v-list dense subheader>
          <div v-if="order.allowBooking">
          <v-subheader style="font-size: 16px">
            <v-icon class="mr-2" color="grey">location_on</v-icon>
            Booking Details</v-subheader
          >
          <v-divider></v-divider>
          <v-list-item @click="!order.bookingRequestStatus?submitBookingRequest():null">
            <v-list-item-action>
              <v-icon color="grey"
                >send</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!order.bookingRequestStatus">
                Submit booking request
              </v-list-item-title>
              <v-list-item-title v-else>Booking Request
                </v-list-item-title>
              <!-- <v-list-item-subtitle> Origin Country </v-list-item-subtitle> -->
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small v-if="order.bookingRequestStatus" :color="order.bookingRequestStatus == 'SUBMITTED'?'blue':order.bookingRequestStatus=='ACCEPTED'?'success':'red'">{{ order.bookingRequestStatus }}</v-chip>

              <v-progress-circular color="blue" indeterminate size="20" v-if="submittingBookingRequest"></v-progress-circular>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="order.bookingRequestStatus && order.bookingRequestStatus == 'ACCEPTED'">
            <v-list-item-action>
              <v-icon color="grey"
                >grade</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Booking Status
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip :color="statusColor(order.state)" small>{{ order.state }}
                <!-- <v-icon small right>arrow_drop_down</v-icon> -->
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="order.bookingHandler">
            <v-list-item-action>
          <v-avatar size="32" :color="order.bookingHandler.logo?'white':'secondary'">
          <v-img :src="order.bookingHandler.logo" v-if="order.bookingHandler.logo" contain> </v-img>
          <h3 v-else>
            {{ order.bookingHandler.name.charAt(0) }}
          </h3>
          </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ order.bookingHandler.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Booking Handler
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top v-if="order.user">
                          <template v-slot:activator="{ on }">
                            <v-avatar
                              style="cursor: pointer"
                              size="32"
                              class="ml-1"
                              v-on="on"
                              :color="order.user.avatar ? 'white' : 'secondary'"
                            >
                              <v-img
                                v-if="order.user.avatar"
                                contain
                                :src="order.user.avatar"
                              ></v-img>
                              <h4 v-else>
                                {{ order.user.firstname.charAt(0) }}
                              </h4>
                            </v-avatar>
                          </template>
                          <span style="font-size: 12px"
                            >Assigned User: {{ order.user.firstname }}
                            {{ order.user.surname }}</span
                          >
                        </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </div>
          <v-subheader style="font-size: 16px">
            <v-icon class="mr-2" color="grey">location_on</v-icon>
            Locations</v-subheader
          >
          <v-divider></v-divider>
          <v-list-item @click="(countryType = 'Origin'), (countryModal = true)" :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null">
            <v-list-item-action>
              <v-icon :color="!order.originCountry ? 'secondary' : 'grey'"
                >public</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="order.originCountry">
                {{ order.originCountry.name }}
              </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle> Origin Country </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar size="32">
                <v-img
                  contain
                  v-if="order.originCountry"
                  :src="`https://cdn.loglive.io/flags/4x3/${order.originCountry.iso2.toLowerCase()}.svg`"
                ></v-img>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="polModal = true"  :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null">
            <v-list-item-action>
              <v-icon :color="!order.portOfLoadValue ? 'secondary' : 'grey'"
                >anchor</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="order.portOfLoadCity">
                {{ order.portOfLoadCity }}
              </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle> POL </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip v-if="order.portOfLoadValue" pill small outlined>
                <v-avatar size="24" left>
                  <v-img
                    contain
                    v-if="order.portOfLoadValue"
                    :src="`https://cdn.loglive.io/flags/4x3/${order.portOfLoadValue
                      .substring(0, 2)
                      .toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
                {{ order.portOfLoadValue }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="podModal = true" :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null">
            <v-list-item-action>
              <v-icon
                :color="!order.portOfDischargeValue ? 'secondary' : 'grey'"
                >anchor</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="order.portOfDischargeCity">
                {{ order.portOfDischargeCity }}
              </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle> POD </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip v-if="order.portOfDischargeValue" pill small outlined>
                <v-avatar size="24" left>
                  <v-img
                    contain
                    v-if="order.portOfDischargeValue"
                    :src="`https://cdn.loglive.io/flags/4x3/${order.portOfDischargeValue
                      .substring(0, 2)
                      .toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
                {{ order.portOfDischargeValue }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="fdModal = true" :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null">
            <v-list-item-action>
              <v-icon
                :color="!order.finalDestinationValue ? 'secondary' : 'grey'"
                >location_on</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="order.finalDestinationCity">
                {{ order.finalDestinationCity }}
              </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle> Final Destination </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip v-if="order.finalDestinationValue" pill small outlined>
                <v-avatar size="24" left>
                  <v-img
                    contain
                    v-if="order.finalDestinationValue"
                    :src="`https://cdn.loglive.io/flags/4x3/${order.finalDestinationValue
                      .substring(0, 2)
                      .toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
                {{ order.finalDestinationValue }}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            @click="(countryType = 'Destination'), (countryModal = true)" :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null"
          >
            <v-list-item-action>
              <v-icon :color="!order.destinationCountry ? 'secondary' : 'grey'"
                >public</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="order.destinationCountry">
                {{ order.destinationCountry.name }}
              </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle> Destination Country </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar size="32">
                <v-img
                  contain
                  v-if="order.destinationCountry"
                  :src="`https://cdn.loglive.io/flags/4x3/${order.destinationCountry.iso2.toLowerCase()}.svg`"
                ></v-img>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>

          <v-subheader style="font-size: 16px">Transport </v-subheader>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-action v-if="!order.shippingLine" :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null">
              <v-icon color="secondary">directions_boat</v-icon>
            </v-list-item-action>
            <v-list-item-avatar v-else>
              <v-avatar color="white" size="36" v-if="order.shippingLine">
                <v-img
                  v-if="order.shippingLine && order.shippingLine.logo"
                  :src="order.shippingLine.logo"
                  contain
                ></v-img>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content v-if="order.vessel" :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null">
              <v-list-item-title>
                {{ order.vessel.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <u
                  style="cursor: pointer"
                  @click="voyageNumberModal = true"
                  v-if="order.mainCarriageConveyanceNumber"
                  >Voyage No: {{ order.mainCarriageConveyanceNumber }}</u
                >
                <u
                  style="cursor: pointer"
                  @click="voyageNumberModal = true"
                  v-else
                  >No Voyage No</u
                >
              </v-list-item-subtitle>
              <v-list-item-subtitle>Vessel/Voyage</v-list-item-subtitle>
              <!-- <v-list-item-subtitle
      v-if="order.shippingLine"
      @click="shippingLineModal = true"
      style="cursor: pointer"
    >
      <span v-if="order.shippingLine.friendlyName">{{
        order.shippingLine.friendlyName
      }}</span
      ><span v-else>{{ order.shippingLine.name }}</span>
    </v-list-item-subtitle> -->
            </v-list-item-content>
            <v-list-item-content v-else>
              <span style="font-size: 16px">Add Vessel</span>
            </v-list-item-content>
            <v-list-item-action>
              <v-row justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null"
                      v-on="on"
                      color="teal"
                      icon
                      @click="openSailingSchedules()"
                    >
                      <v-icon>event</v-icon></v-btn
                    >
                  </template>
                  <span style="font-size: 12px">Search Sailing Schedules</span>
                </v-tooltip>
                <!-- <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            color="blue"
            icon
            @click="vesselModal = true"
          >
            <v-icon>manage_search</v-icon></v-btn
          >
        </template>
        <span style="font-size: 12px">Search Vessels</span>
      </v-tooltip> -->
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="shippingLineModal = true" :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null">
            <v-list-item-action v-if="!order.shippingLine">
              <v-icon color="secondary">directions_boat</v-icon>
            </v-list-item-action>
            <v-list-item-avatar v-else>
              <v-avatar color="white" size="36" v-if="order.shippingLine">
                <v-img
                  v-if="order.shippingLine && order.shippingLine.logo"
                  :src="order.shippingLine.logo"
                  contain
                ></v-img>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-if="order.shippingLine">{{
                order.shippingLine.name
              }}</v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle>Shipping Line</v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
            <v-list-item v-if="!order.vessel">
                          <v-list-item-action>
                            <v-icon :color="order.eta ? 'success' : 'secondary'"
                              >today</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="order.plannedWeek">
                              Week {{ order.plannedWeek }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Planned Week
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-container>
                              <v-row justify="center">
                                <v-autocomplete :items="weekNumbers" v-model="order.plannedWeek" hide-details outlined dense  @input="handleOrderChange"></v-autocomplete>
                              </v-row>
                            </v-container>
                          </v-list-item-action>
                        </v-list-item>

                        <v-list-item v-if="!order.vessel">
                          <v-list-item-action>
                            <v-icon :color="order.eta ? 'success' : 'secondary'"
                              >today</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="order.plannedYear">
                              Week {{ order.plannedYear }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Planned Year
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-container>
                              <v-row justify="center">
                                <v-autocomplete :items="[(new Date().getFullYear()), (new Date().getFullYear()+1)]" v-model="order.plannedYear" hide-details outlined dense    @input="handleOrderChange"></v-autocomplete>
                              </v-row>
                            </v-container>
                          </v-list-item-action>
                        </v-list-item>
          <v-row v-if="order.vessel">
            <v-col cols="12" sm="6">
              <v-menu :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-action>
                      <v-icon :color="order.etd ? 'success' : 'secondary'"
                        >today</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="order.etd">
                        {{ formatDateOnly(order.etd) }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle> ETD </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-date-picker
                  v-model="order.etd"
                  no-title
                  scrollable
                  @change="$emit('orderChange')"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-action>
                      <v-icon :color="order.eta ? 'success' : 'secondary'"
                        >today</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="order.eta">
                        {{ formatDateOnly(order.eta) }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle> ETA </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-date-picker
                  v-model="order.eta"
                  no-title
                  scrollable
                  @change="$emit('orderChange')"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-list>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="countryModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Select {{ countryType }} Country </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="countryModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            autofocus
            dense
            hide-details
            v-model="searchCountries"
            clearable
            placeholder="Search"
            prepend-inner-icon="search"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="country in filterCountries"
              :key="country.id"
              @click="setCountry(country)"
            >
              <v-list-item-action>
                <v-avatar size="24" left>
                  <v-img
                    contain
                    v-if="country.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ country.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterCountries.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No countries found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sailingScheduleModal"
      persistent
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">directions_boat</v-icon>
            Search Sailing Schedules
          </v-toolbar-title>
          <v-chip
            v-if="order.portOfLoadValue"
            small
            outlined
            style="border: none"
            class="ml-2"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="order.portOfLoadValue"
                :src="`https://cdn.loglive.io/flags/4x3/${order.portOfLoadValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ order.portOfLoadValue }}
          </v-chip>
          <v-icon class="mx-3" color="grey" small>east</v-icon>
          <v-chip
            v-if="order.finalDestinationValue"
            small
            outlined
            style="border: none"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="order.finalDestinationValue"
                :src="`https://cdn.loglive.io/flags/4x3/${order.finalDestinationValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ order.finalDestinationValue }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn text @click="sailingScheduleModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <ScheduleModal
            :portOfLoadValue="order.portOfLoadValue"
            :finalDestinationValue="order.portOfDischargeValue"
            :shippingLines="shippingLines"
            @select="selectSchedule"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="shippingLineModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Shipping Line</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shippingLineModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            dense
            outlined
            rounded
            prepend-inner-icon="search"
            autofocus
            placeholder="Search"
            clearable
            v-model="searchShippingLine"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="carrier in filterShippingLines"
              :key="carrier.id"
              @click="setShippingLine(carrier)"
            >
              <v-list-item-action>
                <v-avatar v-if="carrier.logo" color="white">
                  <v-img :src="carrier.logo" contain></v-img>
                </v-avatar>
                <v-avatar v-else color="secondary">
                  <h3>{{ carrier.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ carrier.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  carrier.friendlyName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="voyageNumberModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat dense color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="voyageNumberModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Voyage No"
            v-model="order.mainCarriageConveyanceNumber"
            outlined
            dense
            clearable
            @change="$emit('orderChange')"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

     <!-- POL Modal -->
     <v-dialog
      v-model="polModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">anchor</v-icon> Manage POL
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="polModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-if="order.consigneeProfile"
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="searchOrigin"
            hide-details
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-else
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <div
            v-if="
              order.consigneeProfile &&
              !order.consigneeProfile.allOriginPorts
            "
          >
            <v-list dense>
              <v-list-item
                v-for="port in loadingPorts"
                :key="port.id"
                @click="setPort('portOfLoad', port)"
              >
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img
                      contain
                      v-if="port.code"
                      :src="`https://cdn.loglive.io/flags/4x3/${port.code
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    color="success"
                    v-if="port.code == order.portOfLoadValue"
                  >
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="order.consigneeProfile">
            <v-progress-linear
              v-if="loadingOriginPorts"
              color="primary"
              indeterminate
            >
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="filterOriginPort.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="filterOriginPort"
                    :key="originPortKey"
                    height="500"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfLoad', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'MARK', 'Origin')"
                            v-if="!item.organisationPorts[0]"
                            ><v-icon>star_border</v-icon></v-btn
                          >
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'UNMARK', 'Origin')"
                            v-if="item.organisationPorts[0]"
                            ><v-icon color="yellow">star</v-icon></v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterOriginPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No ports found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear
              v-if="loadingPortSearch"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="portResults.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="portResults"
                    height="250"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfLoad', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px"
                        >No results found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POD Modal -->
    <v-dialog
      v-model="podModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">anchor</v-icon> Manage POD
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="podModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-if="order.consigneeProfile"
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="searchDestination"
            hide-details
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            v-else
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <div
            v-if="
              order.consigneeProfile &&
              !order.consigneeProfile.allDestinationPorts
            "
          >
            <v-list dense>
              <v-list-item
                v-for="port in dischargePorts"
                :key="port.id"
                @click="setPort('portOfDischarge', port)"
              >
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img
                      contain
                      v-if="port.code"
                      :src="`https://cdn.loglive.io/flags/4x3/${port.code
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    color="success"
                    v-if="port.code == order.portOfDischargeValue"
                  >
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="order.consigneeProfile">
            <v-progress-linear
              v-if="loadingDestinationPorts"
              color="primary"
              indeterminate
            >
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="filterDestinationPort.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="filterDestinationPort"
                    height="500"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfDischarge', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'MARK', 'Destination')"
                            v-if="!item.organisationPorts[0]"
                            ><v-icon>star_border</v-icon></v-btn
                          >
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="
                              favouritePort(item, 'UNMARK', 'Destination')
                            "
                            v-if="item.organisationPorts[0]"
                            ><v-icon color="yellow">star</v-icon></v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterDestinationPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No ports found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear
              v-if="loadingPortSearch"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="portResults.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="portResults"
                    height="250"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfDischarge', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px"
                        >No results found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Final Destination Modal -->
    <v-dialog
      v-model="fdModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">location_on</v-icon> Manage
            Final Destination
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="fdModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <v-progress-linear
            v-if="loadingPortSearch"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0">
              <v-card-text class="my-0 py-0">
                <v-virtual-scroll
                  v-if="portResults.length > 0"
                  class="my-0 py-0"
                  :bench="0"
                  :items="portResults"
                  height="250"
                  item-height="50"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item
                      :key="item.id"
                      @click="setPort('finalDestination', item)"
                    >
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img
                            contain
                            v-if="item.locode"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-size: 12px; color: grey"
                        >
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px"
                      >No results found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sailingScheduleError"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-text class="text-left mt-3 pt-5">
          <p style="font-size: 16px">
            Please select make sure to select the following before continuing:
          </p>
          <ul>
            <li v-if="!order.portOfLoadValue">Port of Load (POL)</li>
            <li v-if="!order.portOfDischargeValue">Port of Discharge (POD)</li>
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="blue"
              class="mx-2"
              text
              small
              @click="sailingScheduleError = false"
              >Okay</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import ScheduleModal from "../Bookings/Dialogs/SailingSchedule.vue";
export default {
  props: ["order", "countries"],
  components: {
    ScheduleModal,
  },
  data: () => ({
    allDestinationPorts: [],
    allOriginPorts: [],
    bookingStatus: [
      { name: "Draft", color: "orange darken-2" },
      { name: "Pending", color: "teal" },
      { name: "Requested", color: "blue" },
      { name: "Confirmed", color: "success" },
      { name: "Rejected", color: "red" },
      { name: "Cancelled", color: "red" },
      { name: "Futile", color: 'red' },
    ],
    countryType: null,
    countryModal: false,
    destinationPortKey: 100,
    originPortKey: 200,
    fdModal: false,
    loadingOriginPorts: false,
    loadingDestinationPorts: false,
    loadingPortSearch: false,
    polModal: false,
    podModal: false,
    portResults: [],
    portSearch: null,
    portSearchQuery: null,
    portSearchTimeout: null,
    sailingScheduleError: false,
    sailingScheduleModal: false,
    searchCountries: null,
    searchDestination: null,
    searchOrigin: null,
    searchShippingLine: null,
    shippingLines: [],
    shippingLineModal: false,
    submittingBookingRequest: false,
    voyageNumberModal: false,
  }),
  watch: {
    "order.consigneeProfileId": {
      immediate: true,
      handler(val) {
        if (this.order.consigneeProfile) {
          this.getAllPorts(this.order.consigneeProfile);
        }
      },
    },
    async portSearch(val) {
      if (this.loadingPortSearch) {
        clearTimeout(this.portSearchTimeout);
      }
      if (this.loadingPortSearch && this.portSearchQuery) {
        this.portSearchQuery.abort();
      }
      this.loadingPortSearch = true;
      let params = {
        search: val,
        portType: "port",
      };
      this.portSearchTimeout = setTimeout(async () => {
        this.portSearchQuery = new AbortController();
        const signal = this.portSearchQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingPortSearch = false;
      }, 750);
    },
  },
  computed: {
    weekNumbers(){
      let result = []
      for(let i = 1; i <= 52; i++){
        result.push(i)
      }
      return result
    },
    dischargePorts() {
      let result =
        this.order.consigneeProfile &&
        this.order.consigneeProfile.consigneeProfilePorts
          ? this.order.consigneeProfile.consigneeProfilePorts.filter(
              (x) => x.type == "discharge"
            )
          : [];
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase())) ||
            (x.code &&
              x.code
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
    loadingPorts() {
      let result =
        this.order.consigneeProfile &&
        this.order.consigneeProfile.consigneeProfilePorts
          ? this.order.consigneeProfile.consigneeProfilePorts.filter(
              (x) => x.type == "loading"
            )
          : [];
      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name.toLowerCase().includes(this.searchOrigin.toLowerCase())) ||
            (x.code &&
              x.code.toLowerCase().includes(this.searchOrigin.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
    filterCountries() {
      let result = this.countries;
      if (this.searchCountries) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountries.toLowerCase())
        );
      }
      return result;
    },
    filterDestinationPort() {
      let result = this.allDestinationPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase()) ||
            x.locode
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase())
        );
      }
      return result;
    },
    filterOriginPort() {
      let result = this.allOriginPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });

      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            x.name.toLowerCase().includes(this.searchOrigin.toLowerCase()) ||
            x.locode.toLowerCase().includes(this.searchOrigin.toLowerCase())
        );
      }
      return result;
    },
    filterShippingLines() {
      let result = this.shippingLines;
      if (this.searchShippingLine) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchShippingLine.toLowerCase()) ||
            (x.friendlyName &&
              x.friendlyName
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase()))
        );
      }
      return result;
    },
  },
  async created() {
    this.shippingLines = await this.$API.getShippingLines();
  },
  methods: {
    handleOrderChange(){
      this.$emit('orderChange')
    },

    async favouritePort(item, type, arrayType) {
      if (arrayType == "Origin") {
        let index = this.allOriginPorts.findIndex((x) => x.id == item.id);
        if (type == "MARK") {
          if (index > -1) {
            let port = this.allOriginPorts.splice(index, 1);
            port[0].organisationPorts = [{ ...item }];
            this.allOriginPorts.unshift(port[0]);
            this.originPortKey++;
          }
          let result = await this.$API.createOrgFavouritePort({
            unLocodeId: item.id,
          });
          result
            ? this.$message.success("Port added to favourites")
            : this.$message.error("Error adding port to favourites");
        } else {
          if (index > -1) {
            let port = this.allOriginPorts.splice(index, 1);
            port[0].organisationPorts = [];
            this.allOriginPorts.unshift(port[0]);
            this.originPortKey++;
          }
          let result = await this.$API.updateOrgFavouritePort({
            unLocodeId: item.id,
          });
          if (!result) {
            this.$message.error("Error removing port from favourites");
          }
        }
      } else {
        let index = this.allDestinationPorts.findIndex((x) => x.id == item.id);
        if (type == "MARK") {
          if (index > -1) {
            let port = this.allDestinationPorts.splice(index, 1);
            port[0].organisationPorts = [{ ...item }];
            this.allDestinationPorts.unshift(port[0]);
            this.destinationPortKey++;
          }
          let result = await this.$API.createOrgFavouritePort({
            unLocodeId: item.id,
          });
          result
            ? this.$message.success("Port added to favourites")
            : this.$message.error("Error adding port to favourites");
        } else {
          if (index > -1) {
            let port = this.allDestinationPorts.splice(index, 1);
            port[0].organisationPorts = [];
            this.allDestinationPorts.unshift(port[0]);
            this.destinationPortKey++;
          }
          let result = await this.$API.updateOrgFavouritePort({
            unLocodeId: item.id,
          });
          if (!result) {
            this.$message.error("Error removing port from favourites");
          }
        }
      }
    },
    formatDate(date) {
      let isoFormat = null;
      if (date) {
        isoFormat = dateFormat(new Date(date), "yyyy-mm-dd HH:MM");
      }
      return isoFormat;
    },
    formatDateOnly(date) {
      let isoFormat = null;
      if (date) {
        isoFormat = dateFormat(new Date(date), "yyyy-mm-dd");
      }
      return isoFormat;
    },
    async getAllPorts(profile) {
      if (profile.originCountry) {
        this.allOriginPorts = await this.$API.getPortsByCountry(
          profile.originCountry.iso2
        );
      }
      if (profile.destinationCountry) {
        this.allDestinationPorts = await this.$API.getPortsByCountry(
          profile.destinationCountry.iso2
        );
      }
    },
    openSailingSchedules() {
      if (this.order.portOfLoadValue && this.order.finalDestinationValue) {
        this.sailingScheduleModal = true;
      } else {
        this.sailingScheduleError = true;
      }
    },
    selectSchedule(schedule) {
      // console.log('new schedule eta', schedule.eta)
      this.order.carrier = schedule.carrier;
      this.order.vessel = schedule.vessel;
      this.order.vesselId = schedule.vesselId;
      this.order.voyage = schedule.voyage;
      this.order.sailingSchedule = schedule;
      this.order.sailingScheduleId = schedule.id;
      this.order.carrierScac = schedule.scac;
      this.order.serviceName = schedule.serviceName;
      this.order.eta = schedule.eta;
      this.order.etd = schedule.etd;
      this.order.shippingLine = schedule.shippingLine;
      this.order.shippingLineId = schedule.shippingLineId;
      this.order.carrierName = schedule.carrier;
      this.order.mainCarriageVesselName = schedule.vesselDescription;
      this.order.mainCarriageConveyanceNumber = schedule.voyage;
      this.order.stackDate = schedule.stackDate;
      this.order.stackDateId = schedule.stackDateId;
      //   let terms = [];
      //   if (this.order.consigneeProfile) {
      //     for (
      //       let i = 0;
      //       i < this.order.consigneeProfile.consigneeProfileIncoTerms.length;
      //       i++
      //     ) {
      //       let term = this.order.consigneeProfile.consigneeProfileIncoTerms[i];
      //       let result = term.consigneeProfileShippingContracts
      //         .filter((x) => x.shippingLine)
      //         .map((y) => ({
      //           id: term.id,
      //           incoTerm: term.incoTerm,
      //           shippingLine: y.shippingLine,
      //           scacCode: y.shippingLine.scacCode,
      //           contractNumber: y.contractNumber,
      //           contractOwner: y.contractOwner,
      //         }));
      //       terms = [...terms, ...result];
      //     }
      //   }
      //   this.suggestedDealTerms = terms.filter(
      //     (x) => x.scacCode == this.order.carrierScac
      //   );
      //   if (this.suggestedDealTerms.length == 1) {
      //     this.order.contractOwner = this.suggestedDealTerms[0].contractOwner;
      //     this.order.contractNumber = this.suggestedDealTerms[0].contractNumber;
      //   }
      this.sailingScheduleModal = false;
      this.$emit("orderChange");
    },
    setCountry(country) {
      if (this.countryType == "Origin") {
        this.order.originCountry = country;
        this.order.originCountryId = country.id;
      } else if (this.countryType == "Destination") {
        this.order.destinationCountry = country;
        this.order.destinationCountryId = country.id;
      }
      this.$emit("orderChange");
      this.countryModal = false;
    },
    setPort(type, item) {
      if (type == "portOfLoad") {
        this.order.portOfLoadValue = item.locode ?? item.code;
        this.order.portOfLoadCity = item.name;
        if (!this.order.originCountry) {
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.order.portOfLoadValue.substring(0, 2)
          );
          if (findCountry) {
            this.order.originCountry = findCountry;
            this.order.originCountryId = findCountry.id;
          }
        }
      } else if (type == "portOfDischarge") {
        this.order.portOfDischargeValue = item.locode ?? item.code;
        this.order.portOfDischargeCity = item.name;
        if (!this.order.finalDestinationValue) {
          this.order.finalDestinationValue = item.locode ?? item.code;
          this.order.finalDestinationCity = item.name;
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.order.finalDestinationValue.substring(0, 2)
          );
          if (findCountry) {
            this.order.destinationCountry = findCountry;
            this.order.destinationCountryId = findCountry.id;
          }
        }
      } else if (type == "finalDestination") {
        this.order.finalDestinationValue = item.locode ?? item.code;
        this.order.finalDestinationCity = item.name;
        let findCountry = this.countries.find(
          (x) => x.iso2 == this.order.finalDestinationValue.substring(0, 2)
        );
        if (findCountry) {
          this.order.destinationCountry = findCountry;
          this.order.destinationCountryId = findCountry.id;
        }
        if (!this.order.portOfDischargeValue) {
          this.order.portOfDischargeValue = item.locode ?? item.code;
          this.order.portOfDischargeCity = item.name;
        }
      }
      this.portSearch = null;
      this.polModal = false;
      this.podModal = false;
      this.fdModal = false;
      this.$emit("orderChange");
    },

    setShippingLine(item) {
      this.order.shippingLine = item;
      this.order.shippingLineId = item.id;
      this.shippingLineModal = false;
      if (!this.order.mainCarriageConveyanceNumber) {
        this.voyageNumberModal = true;
      }
      this.$emit("orderChange");
    },

    setVessel(vessel) {
      this.order.vessel = vessel;
      this.order.vesselId = vessel.id;
      this.order.mainCarriageVesselName = vessel.name;
      this.vesselModal = false;
      if (!this.order.shippingLine) {
        this.shippingLineModal = true;
      } else {
        this.voyageNumberModal = true;
      }
      this.$emit("orderChange");
    },
    async submitBookingRequest(){
      this.submittingBookingRequest = true;
      await this.$API.updateBooking({
        bookingRequestStatus: "SUBMITTED",
        id: this.order.id,
        bookingHandlerId: this.order.forwarderId,
        customerId: this.$store.state.currentOrg.id
      })
      this.$message.success("Booking request submitted")
      this.order.bookingRequestStatus = "SUBMITTED"
      this.submittingBookingRequest = false
    },
    statusColor(status) {
      let color = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      ).color;
      return color;
    },
  },
};
</script>
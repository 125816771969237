<template>
    <div>
      <v-card :elevation=" !standalone ? '0': ''">
        <v-toolbar flat>
          <v-toolbar-title>
            <span v-if="creditNote.id">Edit</span>
            <span v-else>Add</span>
             Credit Note 
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-chip small class="mx-1" v-if="creditNote.totalExclVAT">
           Total Ex VAT: {{ getSymbol(creditNote.currencyCode) }}{{ formatNumber(creditNote.totalExclVAT) }}
          </v-chip>
          <v-chip small class="mx-1" v-if="creditNote.totalInclVAT">
           Total: {{ getSymbol(creditNote.currencyCode) }}{{ formatNumber(creditNote.totalInclVAT) }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn v-if="!creditNote.id" icon color="primary" :loading="savingCreditNote" @click="saveCreditNote()" :disabled="!creditNote.customerId || !creditNote.invoiceId || !creditNote.contactId || creditNote.creditNoteLineItems.length == 0">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn v-if="creditNote.id && editing" icon color="primary" :loading="savingCreditNote" @click="checkUpdateType()" :disabled="!creditNote.customerId || !creditNote.invoiceId || !creditNote.contactId || creditNote.creditNoteLineItems.length == 0">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn icon color="teal" @click="viewCreditNote()" v-if="creditNote.creditNoteURL">
            <v-icon>launch</v-icon>
          </v-btn>
          <v-btn text @click="$emit('close')"> X</v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- <v-container> -->
            <v-row class="my-2">
              <v-col cols="12" sm="6" :md="standalone ? '4': '8'" class="mb-0 pb-0">
                <v-autocomplete
                  label="Customer*"
                  :items="organisations.data"
                  item-text="relatedOrganisation.name"
                  item-value="relatedOrganisation.id"
                  hide-details
                  outlined
                  v-model="creditNote.customerId"
                  @change="setAccountingContactId()"
                  clearable
                  dense
                >
                  <template v-slot:selection="data">
                    <v-row align="center">
                      <v-avatar
                        size="32"
                        :color="
                          data.item && data.item.relatedOrganisation.logo
                            ? 'white'
                            : 'secondary'
                        "
                        class="mx-2"
                        v-if="data.item"
                      >
                        <v-img
                          v-if="data.item.relatedOrganisation.logo"
                          contain
                          :src="data.item.relatedOrganisation.logo"
                        ></v-img>
                        <h3 v-else>
                          {{ data.item.relatedOrganisation.name.charAt(0) }}
                        </h3>
                      </v-avatar>
                      <span v-if="data.item.relatedOrganisation">
                        {{ data.item.relatedOrganisation.name }}
                      </span>
                    </v-row>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-action>
                      <v-avatar
                        size="32"
                        :color="
                          data.item.relatedOrganisation.logo
                            ? 'white'
                            : 'secondary'
                        "
                      >
                        <v-img
                          contain
                          v-if="data.item.relatedOrganisation.logo"
                          :src="data.item.relatedOrganisation.logo"
                        ></v-img>
                        <h3 v-else>
                          {{ data.item.relatedOrganisation.name.charAt(0) }}
                        </h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item.relatedOrganisation.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        v-if="data.item.relatedOrganisation.alias"
                        >{{
                          data.item.relatedOrganisation.alias
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                  <template v-slot:append>
                    <v-tooltip top v-if="creditNote.customerId && creditNote.contactId">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="success"
                          >check_circle_outline</v-icon
                        >
                      </template>
                      <span>Contact linked to accounting</span>
                    </v-tooltip>
  
                    <v-tooltip
                      top
                      v-else-if="creditNote.customerId && !creditNote.contactId"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="red">error</v-icon>
                      </template>
                      <span>Contact not linked to accounting</span>
                    </v-tooltip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="mb-0 pb-0">
                <v-text-field
                  label="Reference"
                  v-model="creditNote.reference"
                  outlined
                  dense
                  clearable
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="mb-0 pb-0">
                <v-select v-if="standalone"
                outlined
                dense
                label="Invoice*"
                :items="invoices"
                item-text="invoiceNumber"
                item-value="id"
                v-model="creditNoteInvoiceId"></v-select>
                <v-text-field v-else
                outlined
                label="Invoice"
                dense
                disabled
                v-model="creditNote.invoiceNumber"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="mb-0 pb-0" 
              :key="invUp">
                <!-- :items="[ 'DRAFT', 'SUBMITTED', 'AUTHORISED'] " -->
                 
                <v-select v-if="!invoice"
                label="Status no invoice"
                v-model="creditNote.status"
                :items="[ 'DRAFT', 'SUBMITTED']"
                  outlined
                  dense
                  clearable
                  hide-details
                >
                </v-select>
                <v-select v-else-if="creditNote.id"
                label="Status with id"
                v-model="creditNote.status"
                :items="invoice.status === 'AUTHORISED' ? [ 'DRAFT', 'SUBMITTED', 'AUTHORISED','DELETED'] :[ 'DRAFT', 'SUBMITTED','DELETED'] "
                  outlined
                  dense
                  clearable
                  hide-details
                >
                </v-select>
                <v-select v-else
                label="Status no id"
                v-model="creditNote.status"
                :items="invoice.status === 'AUTHORISED' ? [ 'DRAFT', 'SUBMITTED', 'AUTHORISED'] :[ 'DRAFT', 'SUBMITTED'] "
                  outlined
                  dense
                  clearable
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="mb-0 pb-0">
                <v-autocomplete :items="currencies" label="Currency" v-model="creditNote.currencyCode" outlined dense></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="mb-0 pb-0">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-left" style="height: 40px">
                      <v-list-item-icon>
                        <v-icon color="primary">event</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 13px">
                          {{ formatDate(creditNote.date) }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 13px">
                          Issue Date
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-date-picker v-model="creditNote.date" no-title scrollable>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="4" class="my-0 py-0">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-left" style="height: 40px">
                      <v-list-item-icon>
                        <v-icon color="primary">event</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 13px">
                          {{ formatDate(creditNote.dueDate) }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 13px">
                          Due Date
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-date-picker v-model="creditNote.dueDate" no-title scrollable>
                  </v-date-picker>
                </v-menu>
              </v-col> -->
              <v-col cols="12" class="my-0 py-0">
                <!-- <v-divider></v-divider> -->
                <v-subheader style="font-size: 16px" class="my-0 py-0">
                  <v-icon small class="mr-2" color="grey">list</v-icon>
                  Line Items
                  <v-btn icon color="primary" @click="addLineItem()">
                    <v-icon>add_circle_outline</v-icon>
                  </v-btn>
                </v-subheader>
                <v-data-table
                  :headers="invoiceHeaders"
                  dense
                  :items="creditNote.creditNoteLineItems"
                 
                >
                <template v-slot:[`item.action`]="{ item }">
                  <v-container>
                    <v-row justify="center">
                        <v-btn icon @click="editLineItem(item)">
                          <v-icon small>edit</v-icon>
                        </v-btn>
                        <v-btn icon color="red" @click="deleteLineItem(item)">
                          <v-icon small>delete</v-icon>
                        </v-btn>
                    </v-row>
                  </v-container>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                <v-select
                    :items="['ACCOUNT', 'ITEM']"
                    style="font-size: 12px"
                    v-model="item.type"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                  </template>
                <template v-slot:[`item.code`]="{ item }">
                  <v-autocomplete
                    style="font-size: 12px"
                    :items="filterTableItems(item.type)"
                    v-model="item.code"
                    item-value="code"
                    item-text="name"
                    outlined
                    dense
                    hide-details
                  ></v-autocomplete>
  </template>
                
                <template v-slot:[`item.description`]="{ item }">
                  <div v-html="item.description" style="font-size: 11px"></div>
  </template>
                <template v-slot:[`item.taxType`]="{ item }">
                  <div v-if="item.taxType">{{ formatTaxType(item.taxType) }}</div>
  </template>
  <template v-slot:[`item.unitAmount`]="{ item }">
    {{getSymbol(creditNote.currencyCode)}}{{formatNumber(item.unitAmount)}}
  </template>
  <template v-slot:[`item.totalExclVAT`]="{ item }">
    {{getSymbol(creditNote.currencyCode)}}{{formatNumber(item.totalExclVAT)}}
  
  </template>
  <template v-slot:[`item.totalInclVAT`]="{ item }">
    {{getSymbol(creditNote.currencyCode)}}{{formatNumber(item.totalInclVAT)}}
  
  </template>
                </v-data-table>
              </v-col>
            </v-row>
          <!-- </v-container> -->
        </v-card-text>
      </v-card>
  
      <v-dialog v-model="lineItemModal" width="500px" :content-class="!standalone ? 'dialogRight' : ''" :overlay-opacity="!standalone ?'0.15':''">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Add Line Item</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              color="primary"
              @click="saveLineItem"
              :disabled="
                !lineItem.code || !lineItem.quantity || !lineItem.unitAmount
              "
            >
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn text @click="lineItemModal = false"> X </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="my-0 py-0">
                  <v-select
                    label="Type"
                    :items="['ACCOUNT', 'ITEM']"
                    class="my-2"
                    v-model="lineItem.type"
                    outlined
                    dense
                    clearable
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <v-autocomplete
                    v-if="lineItem.type == 'ACCOUNT'"
                    label="Code*"
                    class="my-2"
                    :items="filterAccountItems"
                    v-model="lineItem.code"
                    item-value="code"
                    item-text="name"
                    @change="setDescription"
                    outlined
                    dense
                    clearable
                    hide-details
                  ></v-autocomplete>
                  <v-autocomplete
                    v-else
                    label="Code*"
                    class="my-2"
                    :items="filterItems"
                    v-model="lineItem.code"
                    item-value="code"
                    item-text="name"
                    @change="setDescription"
                    outlined
                    dense
                    clearable
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <v-textarea
                    label="Description"
                    v-model="lineItem.description"
                    outlined
                    dense
                    clearable
                    hide-details
                    auto-grow
                    rows="4"
                    class="my-2"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <v-text-field
                    label="Quantity"
                    v-model="lineItem.quantity"
                    type="number"
                    outlined
                    dense
                    clearable
                    hide-details
                    class="my-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <v-text-field
                    label="Unit Price"
                    :prefix="getSymbol(creditNote.currencyCode)"
                    v-model="lineItem.unitAmount"
                    outlined
                    dense
                    clearable
                    hide-details
                    class="my-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <v-select
                    label="Tax Type"
                    :items="taxTypes"
                    item-text="name"
                    item-value="code"
                    v-model="lineItem.taxType"
                    outlined
                    dense
                    clearable
                    hide-details
                    class="my-2"
                  ></v-select>
                </v-col>
                <!-- <v-col cols="12" class="my-0 py-0">
                    <v-text-field
                      label="Total Ex VAT"
                      v-model="lineItem.totalExclVAT"
                      outlined
                      dense
                      clearable
                      hide-details
                      class="my-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    <v-text-field
                      label="Total"
                      v-model="lineItem.totalInclVAT"
                      outlined
                      dense
                      clearable
                      hide-details
                      class="my-2"
                    ></v-text-field>
                  </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import * as CurrencyCodes from "currency-codes";
  import getSymbolFromCurrency from "currency-symbol-map";
  import dateFormat from "dateformat";
  export default {
    props: {
      creditNote: {
        type: Object,
        required: true,
      },
      organisations: {
        type: Object,
        required: true,
      },
      invoices: {
        type: Array,
      },
      invoice: {
        type: Object
      },
      standalone: {
        type: Boolean
      },
      editing: {
        type: Boolean
      }
    },
    data: () => ({
      creditNoteInvoiceId: null,
      accountItems: [],
      accountContacts: [],
      currencies: CurrencyCodes.codes(),
      invoiceHeaders: [
        {text: "Action", value: "action", align: "center", sortable: false, width: '120px'},
        { text: "Type", value: "type", sortable: false, width: '180px' },
        { text: "Code", value: "code", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Quantity", value: "quantity", align: "center", sortable: false },
        { text: "Unit Price", value: "unitAmount", align: "end", sortable: false },
        { text: "Tax Type", value: "taxType", sortable: false },
        { text: "Total Ex VAT", value: "totalExclVAT", align: "end", sortable: false },
        { text: "Total", value: "totalInclVAT", align: "end", sortable: false },
      ],
      lineItem: {},
      lineItemModal: false,
      savingCreditNote: false,
      taxTypes: [],
      invUp: 0,
    }),
    mounted() {
      this.getAccountItems();
      this.getAccountContacts();
      this.getTaxTypes();
      
    },
    watch: {
      "creditNoteInvoiceId":{
        immediate: true,
        handler(val) {
          if(val) {
            this.creditNote.invoiceId = this.creditNoteInvoiceId
            if(!this.invoice) {
              this.invUp++
              this.invoice = this.invoices.find((x) => x.id === this.creditNote.invoiceId)
            } else if(this.invoice.id !== this.creditNote.invoiceId) {
              this.invUp++
              this.invoice = this.invoices.find((x) => x.id === this.creditNote.invoiceId)
            }
          }
        }
      },
      "creditNote.frontendDate": {
        immediate: true,
        handler(val) {
          this.setAccountingContactId();
        }
      },
      "invoice.id": {
        immediate: true,
        handler(val) {
          this.setAccountingContactId();
        }
      },
        "creditNote.id": {
        handler: function (val) {
          if(val) {

            // this.setAccountingContactId();
            if(!this.invoice && this.invoices && this.creditNote.id) {
              this.getInvoice()
            }
          }
        },
        deep: true,
      },
    },
    computed: {
      filterAccountItems() {
        return this.accountItems.filter((item) => item.recordType === "ACCOUNT" && (item.type == "REVENUE" || item.type == "SALES"));
      },
      filterItems() {
        return this.accountItems.filter((item) => item.recordType === "ITEM");
      },
    },
    methods: {
      getInvoice() {
        this.invoice = this.invoices.find((x) => x.id === this.creditNote.invoiceId)
      },
      logItem(item) {
        console.log(item)
      },
      addLineItem() {
        try{
          this.lineItem = {
            type: "ACCOUNT",
            description: null,
            quantity: 1,
            unitAmount: 0,
            taxType: "OUTPUT3",
            totalExclVAT: 0,
            totalInclVAT: 0,
            tempId: new Date().getTime()
          };
          this.lineItemModal = true;
        }catch(e) {
          console.error(e)
        }
      },
      deleteLineItem(item){
        let find = this.creditNote.creditNoteLineItems.find((x) => x.tempId === item.tempId);
        let index = this.creditNote.creditNoteLineItems.indexOf(find);
        this.creditNote.creditNoteLineItems.splice(index, 1);
        this.creditNote.totalExclVAT = parseFloat((this.creditNote.creditNoteLineItems.reduce((acc, item) => acc + Number(item.totalExclVAT), 0)).toFixed(2));
        this.creditNote.totalInclVAT = parseFloat((this.creditNote.creditNoteLineItems.reduce((acc, item) => acc + Number(item.totalInclVAT), 0)).toFixed(2));
      },
      editLineItem(item){
        this.lineItem = item;
        this.lineItemModal = true;
      },
      filterTableItems(type) {
        if (type == "ACCOUNT") {
          return this.filterAccountItems;
        } else {
          return this.filterItems;
        }
      },
      formatDate(date) {
        let result = null;
        if (date) {
          result = dateFormat(new Date(date), "dd-mm-yyyy");
        }
        return result;
      },
      formatNumber(x, isCost = false) {
        if (isCost) {
          x *= -1;
        }
        if (x) {
          let result = (parseFloat(x).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return result;
        } else {
          return null;
        }
      },
      formatTaxType(code) {
        let find = this.taxTypes.find((tax) => tax.code === code);
        return find ? find.name : null;
      },
      async getAccountItems() {
        try {
          this.accountItems = await this.$API.getAccountingItems();
        } catch (error) {
          console.error(error);
        }
      },
      async getAccountContacts() {
        try {
          this.accountContacts = await this.$API.getAccountingContacts();
        } catch (error) {
          console.error(error);
        }
      },
      getSymbol(currencyCode) {
        return getSymbolFromCurrency(currencyCode);
      },
      async getTaxTypes() {
        try {
          this.taxTypes = await this.$API.getAccountingTaxTypes();
        } catch (error) {
          console.error(error);
        }
      },
      saveLineItem() {
        this.lineItem.totalExclVAT = parseFloat((this.lineItem.quantity * this.lineItem.unitAmount).toFixed(2));
        let findTax = this.taxTypes.find((tax) => tax.code === this.lineItem.taxType);
        this.lineItem.totalInclVAT = parseFloat((this.lineItem.totalExclVAT + (this.lineItem.totalExclVAT * findTax.rate) / 100).toFixed(2));
        let findExisting = this.creditNote.creditNoteLineItems.find((item) => item.tempId === this.lineItem.tempId);
        if (findExisting) {
          let index = this.creditNote.creditNoteLineItems.indexOf(findExisting);
          this.creditNote.creditNoteLineItems.splice(index, 1, this.lineItem);
        } else{
          this.creditNote.creditNoteLineItems.push(this.lineItem);
        }
        this.creditNote.totalExclVAT = parseFloat((this.creditNote.creditNoteLineItems.reduce((acc, item) => acc + Number(item.totalExclVAT), 0)).toFixed(2));
        this.creditNote.totalInclVAT = parseFloat((this.creditNote.creditNoteLineItems.reduce((acc, item) => acc + Number(item.totalInclVAT), 0)).toFixed(2));
        this.lineItemModal = false;
      },
      async saveCreditNote() {
        try {
          this.savingCreditNote = true;
          let result = await this.$API.createCreditNote(this.creditNote);
          if(result && result.id){
            this.$message.success("Credit Note created successfully");
            this.savingCreditNote = false
            this.$emit('creditNote-created', result);
            this.$emit("close");
          }
        } catch (error) {
          console.error(error);
          console.error(error.message)
          if(error.message){
            this.$message.error(error.message);
          } else {
            this.$message.error("Failed to create credit note");
          }
          this.savingCreditNote = false
        }
      },
      checkUpdateType() {
      if(this.creditNote.status === "DELETED") {
        this.$confirm("Are you sure you want to delete this Credit Note?", "Confirm", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                    
                    this.updateCreditNote()
                })
      }else {
        this.updateCreditNote()
      }
    },
      async updateCreditNote() {
        try {
          this.savingCreditNote = true;
          let result = await this.$API.updateCreditNote(this.creditNote);
          if(result && result.id){
          if(result.status === 'DELETED') {
            this.$message.success("Credit Note deleted successfully");
          } else {
            this.$message.success("Credit Note updated successfully");
          }
            this.savingCreditNote = false
            this.$emit('creditNote-updated', result);
            this.$emit("close");
          }
        } catch (error) {
          console.error(error);
          console.error(error.message)
          if(error.message){
            this.$message.error(error.message);
          } else {
            this.$message.error("Failed to create credit note");
          }
          this.savingCreditNote = false
        }
      },
      setAccountingContactId() {
        if (this.creditNote.customerId) {
          let findRelationship = this.organisations.data.find(
            (x) => x.relatedOrganisationId == this.creditNote.customerId
          );
          if (findRelationship) {
            this.creditNote.contactId = findRelationship.accountingId;
          } else {
            this.creditNote.contactId = null;
          }
        } else {
          this.creditNote.contactId = null;
        }
      },
      setDescription(val) {
        if(!this.lineItem.description){
          if (val) {
          let find = this.accountItems.find(
            (item) => item.code === val && item.recordType === this.lineItem.type
          );
          this.lineItem.description = find.name;
        } else {
          this.lineItem.description = null;
        }
        }
        
      },
      viewCreditNote(){
        window.open(this.creditNote.creditNoteURL, "_blank")
      }
    },
  };
  </script>

<style scoped>
::v-deep .dialogRight {
  position: fixed !important;
  left: 60% !important;
}
</style>
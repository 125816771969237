<template>
  <div>
    <v-card flat style="width: 100%; height: 100%; background: var(--v-greyRaised-base) !important;">
      <v-toolbar flat v-if="orders.length == 1 && !traderView"> 
        <v-toolbar-title> Assign Stock </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon @click="$emit('close')"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text :style="{height: orders.length == 1 ? '93vh': traderView ? '100%' : '88vh', 'width': '100%'}" class="mt-0 pa-0">
        <div v-if="!loading" style="height: 100%" class="d-flex">
          <div class="leftSections" v-if="traderView">
            <v-list dense class="listLeft">
                <v-list-item v-for="(container, index) in orderItems" :key="index" :color="container.id == selectedContainer.id ? 'edit' : ''" @click="selectedContainer = container" dense class="listItemLeft ml-0">
                    <v-list-item-avatar>
                        <v-icon small>view_column</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{container.containerNo}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{container.ctoNo}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
          </div>
          <div :class="traderView ? 'rightSection' : ''"> 
            <v-row no-gutters>
              <v-col cols="12" sm="3" md="2" v-if="!traderView">
                <v-list dense subheader v-if="orders.length > 1">
                  <v-text-field
                    placeholder="Search"
                    prepend-inner-icon="search"
                    dense
                    outlined
                    rounded
                    clearable
                    v-model="searchCustomer"
                    hide-details
                  ></v-text-field>

                  <v-toolbar flat dense>
                    <v-chip small v-if="filterPort" outlined style="border: none">
                      <v-icon small left color="red" @click="filterPort = null"
                        >cancel</v-icon
                      >
                      {{ filterPort }}
                    </v-chip>
                    <v-chip small pill v-if="filterCustomer">
                      <v-icon
                        small
                        left
                        color="red"
                        @click="filterCustomer = null"
                        >cancel</v-icon
                      >
                      <span v-if="filterCustomer.alias">{{
                        filterCustomer.alias
                      }}</span>
                      <span v-else>
                        {{ filterCustomer.name }}
                      </span>
                    </v-chip>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-progress-linear
                    indeterminate
                    color="primary"
                    v-if="loadingItems"
                  ></v-progress-linear>

                  <div>
                    <v-virtual-scroll
                      :items="filterCustomers"
                      :key="orderKey"
                      class="my-0 py-0 mx-0"
                      :bench="10"
                      height="700"
                      item-height="65"
                    >
                      <template v-slot="{ item }">
                        <v-divider
                          v-if="item.type == 'order' || item.type == 'customer'"
                        ></v-divider>
                        <v-subheader
                          v-if="item.type == 'customer'"
                          style="font-size: 14px"
                        >
                          <v-chip
                            pill
                            outlined
                            style="border: none"
                            @click="setFilterCustomer(item)"
                          >
                            <v-avatar
                              small
                              left
                              size="24"
                              :color="item.logo ? 'white' : 'secondary'"
                            >
                              <v-img
                                v-if="item.logo"
                                :src="item.logo"
                                contain
                              ></v-img>
                              <h3 v-else>{{ item.name.charAt(0) }}</h3>
                            </v-avatar>
                            <span v-if="item.alias">{{ item.alias }}</span>
                            <span v-else>
                              {{ item.name }}
                            </span>
                          </v-chip>
                        </v-subheader>
                        <v-list-item v-else-if="item.type == 'order'">
                          <v-list-item-action class="mx-0 px-0">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  :color="statusColor(item.orderStatus)"
                                  >fiber_manual_record</v-icon
                                >
                              </template>
                              <span
                                >{{ item.orderNo }}: {{ item.orderStatus }}</span
                              >
                            </v-tooltip>
                          </v-list-item-action>
                          <v-list-item-content class="ml-1">
                            <v-list-item-title
                              style="font-size: 14px; font-weight: bold"
                            >
                              <span>{{ item.orderNo }}</span>
                              <v-chip
                                class="ml-2"
                                @click="setFilterPort(item.portOfLoadValue)"
                                v-if="item.portOfLoadValue"
                                label
                                small
                              >
                                {{ item.portOfLoadValue }}
                              </v-chip>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-else-if="item.type == 'container'"
                          style="height: 45px"
                          @click="setActiveContainer(item)"
                          :style="{
                            background:
                              selectedContainer && item.id == selectedContainer.id
                                ? 'rgba(12, 97, 24, 0.4)'
                                : '',
                          }"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.ctoNo }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="item.containerNo">
                              {{ item.containerNo }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle style="font-size: 12px">
                              {{ item.assignedStock }}/{{ item.totalPallets }}
                              Pallets
                            </v-list-item-subtitle>
                            <!-- <v-list-item-subtitle
                            v-if="item.cartons"
                            style="font-size: 12px"
                          >
                            <span> {{ item.cartons }} Cartons </span>
                          </v-list-item-subtitle> -->
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-progress-circular
                              :value="item.assignedProgress"
                              size="20"
                              :color="
                                item.assignedProgress < 20
                                  ? 'red'
                                  : item.assignedProgress < 50
                                  ? 'orange'
                                  : item.assignedProgress < 100
                                  ? 'blue'
                                  : 'success'
                              "
                            ></v-progress-circular>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll>
                  </div>
                </v-list>
                <v-list dense subheader v-else>
                  <v-subheader style="font-size: 16px">
                    Order Items
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    v-for="item in orderItems"
                    :key="item.id"
                    @click="setActiveContainer(item)"
                    :style="{
                      background:
                        selectedContainer && item.id == selectedContainer.id
                          ? 'rgba(114, 159, 217, 0.2)'
                          : '',
                          'border-left': selectedContainer && item.id == selectedContainer.id ? '5px solid var(--v-primary-base)' : '5px solid transparent'
                    }"
                  >
                  <!-- <v-list-item-action>

                  </v-list-item-action> -->
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.ctoNo }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="item.containerNo">
                        {{ item.containerNo }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        {{ item.assignedStock }}/{{ item.totalPallets }}
                        Pallets
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-progress-circular
                        :value="item.assignedProgress"
                        size="20"
                        :color="
                          item.assignedProgress < 20
                            ? 'red'
                            : item.assignedProgress < 50
                            ? 'orange'
                            : item.assignedProgress < 100
                            ? 'blue'
                            : 'success'
                        "
                      ></v-progress-circular>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" :sm="traderView ? 12 : 9" :md="traderView ? 12 : 10" :lg="traderView ? 12 : 10" :class="traderView ? 'px-0' : ''">
                <v-card flat v-if="selectedContainer && selectedContainer.id" :loading="loadingStock" style=" border-radius: 20px; background-color: transparent !important;">
                  <v-toolbar flat dense :color="traderView ? 'greyRaised' : 'modal'" v-if="selectedContainer" :tile="traderView">
                    <v-toolbar-title v-if="!traderView">
                      {{ selectedContainer.ctoNo }}
                      <span v-if="selectedContainer.containerNo"> - {{ selectedContainer.containerNo }}</span >
                    </v-toolbar-title>
                    <v-chip pill outlined style="border: none" v-if="selectedContainer.booking.orderCustomer" class="mx-1" >
                      <v-avatar small left size="24" :color=" selectedContainer.booking.orderCustomer.logo ? 'white' : 'secondary' " >
                        <v-img v-if="selectedContainer.booking.orderCustomer.logo" :src="selectedContainer.booking.orderCustomer.logo" contain ></v-img>
                        <h3 v-else>
                          {{ selectedContainer.booking.orderCustomer.name.charAt(0) }}
                        </h3>
                      </v-avatar>
                      <span v-if="selectedContainer.booking.orderCustomer.alias" >{{ selectedContainer.booking.orderCustomer.alias }}</span>
                      <span v-else>
                        {{ selectedContainer.booking.orderCustomer.name }}
                      </span>
                    </v-chip>
                    <v-chip outlined dense style="border: none">
                      <v-chip v-if="selectedContainer.booking.portOfLoadValue" small outlined style="border: none">
                        <v-avatar size="24" left>
                          <v-img
                            contain
                            v-if="selectedContainer.booking.portOfLoadValue"
                            :src="`https://cdn.loglive.io/flags/4x3/${selectedContainer.booking.portOfLoadValue .substring(0, 2) .toLowerCase()}.svg`" ></v-img>
                        </v-avatar>
                        {{ selectedContainer.booking.portOfLoadValue }}
                      </v-chip>
                      <v-icon small class="mx-1">arrow_forward</v-icon>
                      <v-chip v-if="selectedContainer.booking.finalDestinationValue" small outlined style="border: none">
                        <v-avatar size="24" left>
                          <v-img contain v-if="selectedContainer.booking.finalDestinationValue" :src="`https://cdn.loglive.io/flags/4x3/${selectedContainer.booking.finalDestinationValue.substring(0,2).toLowerCase()}.svg`" />
                        </v-avatar>
                        {{ selectedContainer.booking.finalDestinationValue }}
                      </v-chip></v-chip>
                    <v-spacer></v-spacer>
                    <v-chip style="height: 40px" outlined small class="pr-1 text-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" icon small @click="viewOrder(selectedContainer.booking)">
                            <v-icon small>launch</v-icon>
                          </v-btn>
                        </template>
                        <span>View Order</span>
                      </v-tooltip>
                      <v-icon small color="grey">search</v-icon>
                      <v-text-field class="ml-0 pl-0" placeholder="Search" hide-details rounded clearable dense style="width: 300px" v-model="search" @change="updateSearchResult()" @input="updateSearchResult()" />
                    </v-chip>
                  </v-toolbar>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="4" class="mb-0 pb-0">
                        <v-list dense>
                          <v-row>
                            <v-col cols="12" sm="6" class="mx-0 px-0">
                              <v-list-item :disabled="selectedSSCC == 0" @click="processAllocation()">
                                <v-list-item-action class="mx-0 px-0">
                                  <v-icon color="grey" small>assignment_turned_in</v-icon>
                                </v-list-item-action>
                                <v-list-item-content class="ml-2">
                                  <v-list-item-title>
                                    Save Allocation
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ selectedSSCC }} Selected
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-progress-circular indeterminate color="primary" size="20" v-if="loadingAllocation">
                                  </v-progress-circular>
                                </v-list-item-action>
                              </v-list-item>
                              <v-list-item :disabled="selectedSSCC == selectedContainer.totalPallets" @click="autoAllocate()">
                                <v-list-item-action class="mx-0 px-0">
                                  <v-icon color="amber" small >auto_awesome</v-icon >
                                </v-list-item-action>
                                <v-list-item-content class="ml-2">
                                  <v-list-item-title>
                                    Auto Allocate (FIFO)
                                  </v-list-item-title>
                                  <v-list-item-subtitle v-if="selectedSSCC < selectedContainer.totalPallets">
                                    {{ selectedContainer.totalPallets - selectedSSCC }}
                                    Remaining
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-progress-circular indeterminate color="primary" size="20" v-if="loadingAutoAllocation">
                                  </v-progress-circular>
                                </v-list-item-action>
                              </v-list-item>
                              <v-list-item :disabled="selectedSSCC == 0" @click="removeAllocation()">
                                <v-list-item-action class="mx-0 px-0">
                                  <v-icon color="red" small>cancel</v-icon>
                                </v-list-item-action>
                                <v-list-item-content class="ml-2">
                                  <v-list-item-title>
                                    Clear Allocated
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ selectedSSCC }} Selected
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-progress-circular indeterminate color="primary" size="20" v-if="loadingRemoveAllocation">
                                  </v-progress-circular>
                                </v-list-item-action>
                              </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" class="mx-0 px-0">
                              <v-list-item>
                                <v-list-item-action class="mx-0 px-0">
                                  <v-icon color="grey" small>filter_list</v-icon>
                                </v-list-item-action>
                                <v-list-item-content class="ml-1">
                                  <v-list-item-title>
                                    Display Allocated
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn @click="changeDisplayAllocated()" small v-if="displayAllocated" style="border: 0.25px solid var(--v-success-base)" color="success" icon>
                                  <v-icon small>check</v-icon>
                                </v-btn>
                                <v-btn small @click="changeDisplayAllocated()" v-else icon style="border: 0.25px dotted grey" color="grey">
                                  <v-icon small>check</v-icon>
                                </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-action class="mx-0 px-0">
                                  <v-icon color="grey" small>local_shipping</v-icon>
                                </v-list-item-action>
                                <v-list-item-content class="ml-1">
                                  <v-list-item-title>
                                    Allow Dual Load
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn @click="changeDualLoadStatus()" small v-if="allowDualLoad" style="border: 0.25px solid var(--v-success-base)" color="success" icon>
                                  <v-icon small>check</v-icon>
                                </v-btn>
                                <v-btn small @click="changeDualLoadStatus()" v-else icon style="border: 0.25px dotted grey" color="grey">
                                  <v-icon small>check</v-icon>
                                </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                              <v-list-item @click="pasteSSCC = true">
                                <v-list-item-action class="mx-0 px-0">
                                  <v-icon color="grey" small>content_paste</v-icon>
                                </v-list-item-action>
                                <v-list-item-content class="ml-1">
                                  <v-list-item-title>
                                    Paste SSCC's
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                          </v-row>
                        </v-list>
                      </v-col>
                      <v-col cols="12" sm="4" class="mx-0 px-0 pb-0 mb-0">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" class="mx-0 px-0">
                            <ul style="font-size: 11px; list-style-type: none">
                              <li class="my-0 py-0">
                                <v-row justify="space-between">
                                  <v-col>
                                    <v-icon small class="mr-1" color="teal"
                                      >event</v-icon
                                    >
                                    Average Age
                                  </v-col>
                                  <v-col class="text-right">
                                    <v-chip
                                      x-small
                                      v-if="displayData.length > 0"
                                    >
                                      {{ averageAge }} Days
                                    </v-chip>
                                    <v-chip x-small v-else> N/A </v-chip>
                                  </v-col>
                                </v-row>
                              </li>
                              <li class="my-0 py-0">
                                <v-row justify="space-between">
                                  <v-col>
                                    <v-icon small class="mr-1" color="blue-grey"
                                      >tag</v-icon
                                    >
                                    Total Pallets
                                  </v-col>
                                  <v-col class="text-right">
                                    <v-chip x-small>
                                      {{ calculatePallets }}
                                    </v-chip>
                                  </v-col>
                                </v-row>
                              </li>

                              <li class="my-0 py-0">
                                <v-row justify="space-between">
                                  <v-col>
                                    <v-icon small class="mr-1" color="success"
                                      >check_circle_outline</v-icon
                                    >
                                    Allocated
                                  </v-col>
                                  <v-col class="text-right">
                                    <v-chip x-small>
                                      {{ selectedSSCC }}
                                    </v-chip>
                                  </v-col>
                                </v-row>
                              </li>
                              <li class="my-0 py-0">
                                <v-row justify="space-between">
                                  <v-col>
                                    <v-icon small class="mr-1" color="warning"
                                      >warning</v-icon
                                    >
                                    Unallocated
                                  </v-col>
                                  <v-col class="text-right">
                                    <v-chip x-small>
                                      {{ calculateUnallocatedPallets }}
                                    </v-chip>
                                  </v-col>
                                </v-row>
                              </li>
                            </ul>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            class="text-center mx-0 px-0 mb-0 pb-0"
                          >
                            <v-list dense subheader>
                              <v-subheader style="font-size: 14px">
                                Load Outs <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  @click="createLoadOutInstruction()"
                                  :loading="savingLoadOut"
                                  small
                                  icon
                                >
                                  <v-icon>add_circle_outline</v-icon>
                                </v-btn>
                              </v-subheader>
                              <v-divider></v-divider>
                              <ul
                                style="font-size: 11px; list-style-type: none"
                                :key="loadOutKey"
                              >
                                <li
                                  class="text-left"
                                  v-for="instruction in selectedContainer.loadOutInstructions"
                                  :key="instruction.id"
                                >
                                  <v-btn
                                    class="mr-2"
                                    color="grey"
                                    icon
                                    small
                                    @click="downloadDocument(instruction)"
                                    ><v-icon small>download</v-icon></v-btn
                                  >
                                  <!-- {{instruction}} -->
                                  {{ instruction.locationCode }} - V{{
                                    instruction.version
                                  }}
                                  ({{ instruction.pallets }} Pallets)
                                </li>
                                <li
                                  v-if=" selectedContainer.loadOutInstructions .length == 0 "
                                  style="color: grey"
                                >
                                  No Load Out Instructions
                                </li>
                              </ul>
                            </v-list>
                            <v-chip
                              color="red"
                              class="mx-1 mt-1"
                              small
                              @click="resetFilters()"
                              v-if="filteredValues.length > 0"
                            >
                              <v-icon small left>filter_alt_off</v-icon> Clear
                              Filters
                            </v-chip>

                            <!-- <v-chip
                            class="mx-1 mt-1"
                            small
                            color="blue"
                            v-if="selectedContainer.assignedStock >= 20"
                            @click="sendLoadOutInstruction()"
                            :loading="savingLoadOut"
                          >
                            <v-icon small left>post_add</v-icon> Send Load Out
                          </v-chip> -->
                          </v-col>
                        </v-row>
                      </v-container>
                      </v-col>
                      <v-col cols="12" sm="4" class="mb-0 pb-0">
                        <v-list dense subheader>
                          <v-subheader style="font-size: 14px">Product Specification</v-subheader>
                          <v-divider></v-divider>
                          <v-list-item v-for="(cargo,index) in selectedContainer.orderCargoItems" :key="cargo.id" @click="setProductFilter(cargo)" style="height: 25px">
                            <v-list-item-action clas="mx-0 px-0">
                              <v-chip small>{{ index + 1 }}</v-chip>
                            </v-list-item-action>
                            <v-list-item-content class="ml-0">
                              <v-list-item-subtitle style="font-size: 12px">
                                {{ cargo.quantity }}
                                {{ formatBaseUnit(cargo.quantityType) }}(s)
                                {{ productSpec(cargo) }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-card-text style="max-width: 100%; overflow-x: auto" class="my-0 pa-0" v-if="displayData.length > 0">
                    <div :style="{ width: tableWidth }" class="mt-2">
                      <table :width="'100%'">
                          <td
                            class="text-center mx-0 px-0"
                            style=" cursor: grab; border-right: 1px solid grey; min-height: 20px; min-width: 20px; font-size: 11px;"
                            v-for="(header, index) in stockHeaders"
                            :key="index"
                            :width="header.width ?? '150px'"
                          >
                            <div class="py-0" style="display: flex; flex-direction: column; justify-content: center; flex-wrap: wrap; width: 100%;" :style="{'max-width' : header.width ?? '150px'}">
                              <b style="font-size: 12px">{{ header.text }}</b>
                              <div>
                                <v-btn v-if="!header.disableSort" class="ml-1" icon x-small @click="changeSortBy(header.value)" >
                                  <v-icon v-if="sortBy != header.value" small color="grey" >arrow_upward</v-icon >
                                  <v-icon v-else-if="sortDesc" small >arrow_downward</v-icon >
                                  <v-icon v-else small>arrow_upward</v-icon>
                                </v-btn>
                                <v-badge v-if="header.filterable" :content=" filter[header.value] ? filter[header.value].length : false " :value=" filter[header.value] && filter[header.value].length > 0 " color="primary" offset-y="10" >
                                  <v-menu offset-y left :close-on-content-click="false" >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn v-bind="attrs" v-on="on" x-small color="grey" icon >
                                        <v-icon small>filter_alt</v-icon>
                                      </v-btn>
                                    </template>
                                    <v-card style=" background: var( --v-modal-base ) !important; border-radius: 20px;">
                                      <v-card-text>
                                        <v-list dense style=" background: var( --v-modal-base ) !important; border-radius: 20px;">
                                          <v-text-field
                                            hide-details
                                            rounded
                                            dense
                                            outlined
                                            clearable
                                            prepend-inner-icon="search"
                                            placeholder="Search"
                                            autofocus
                                            v-model="searchHeaders[header.value]"
                                            @change="updateSearchKey()"
                                          />
                                          <v-list
                                            dense
                                            style="
                                              max-height: 50vh;
                                              overflow-y: auto;
                                              background: var(
                                                --v-modal-base
                                              ) !important;
                                            "
                                            :key="searchKey"
                                          >
                                            <v-list-item
                                              v-for="(
                                                item, index
                                              ) in headerOptions[header.value]"
                                              :key="index"
                                            >
                                              <v-list-item-action class="pr-0">
                                                <v-checkbox
                                                  v-model="filter[header.value]"
                                                  :value="item.value"
                                                  hide-details
                                                  dense
                                                  @change="calculateDisplayData()"
                                                ></v-checkbox>
                                              </v-list-item-action>
                                              <v-list-item-content>
                                                <v-list-item-title>
                                                  {{ item.name }}
                                                </v-list-item-title>
                                              </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item
                                              v-if="
                                                headerOptions[header.value]
                                                  .length == 0
                                              "
                                            >
                                              <v-list-item-content
                                                class="text-center"
                                              >
                                                <span style="font-size: 12px"
                                                  >No results found</span
                                                >
                                              </v-list-item-content>
                                            </v-list-item>
                                          </v-list>
                                        </v-list>
                                      </v-card-text>
                                    </v-card>
                                  </v-menu>
                                </v-badge>
                              </div>
                            </div>
                          </td>
                      </table>
                      <!-- <v-divider></v-divider> -->
                      <v-virtual-scroll
                        v-if="displayData.length > 0"
                        :bench="10"
                        :items="displayData"
                        :height="calculatedHeight"
                        :width="'100%'"
                        item-height="35"
                        class="px-1 mt-0 pt-0"
                      >
                        <template v-slot:default="{ item }">
                          <div class="text-center" :style="{ display: 'flex', 'flex-direction': 'row', width: '100%', height: '35px', 'background-color': item.id && selectedPallets.includes(item.id) ? 'rgba(114, 159, 217, 0.2)' : 'transparent', 'font-size': '11px', }" >
                            <td v-for="(header, index) in stockHeaders" :key="index" :style="{ width: header.width ?? '150px' }" class="text-center pt-1 mb-0 pb-0" style="margin-left: 2px !important;" >
                              <div v-if="header.value == 'actions'" class="tex†-center" >
                                <v-btn @click="selectPallet(item)" small v-if="itemSelected(item.id)" style="border: none" color="success" icon >
                                  <v-icon small>check</v-icon>
                                </v-btn>
                                <v-btn small @click="selectPallet(item)" v-else icon style="border: 0.25px dotted grey" color="grey" >
                                  <v-icon small>check</v-icon>
                                </v-btn>
                              </div>
                              <div v-else-if="header.value == 'status'" class="tex†-center" >
                                <v-chip v-if="item[header.value]" small outlined :color=" getPalletStatusColor(item[header.value]) " @click=" addToFilter(item[header.value], header.value) " style="font-size: 11px" >
                                  {{ item[header.value] }}
                                </v-chip>
                              </div>
                              <div v-else-if="header.value == 'palletQuantity'" class="text-center">
                                {{ Math.round((parseFloat(item[header.value]) + Number.EPSILON) * 100) / 100 }}
                              </div>
                              <div v-else-if="header.value == 'targetCountry'" class="text-center">
                                <v-avatar size="24" v-if="item.targetCountry">
                                  <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.targetCountry.substring(0,2).toLowerCase()}.svg`"></v-img>
                                </v-avatar>
                                {{ item[header.value] }}
                              </div>
                              <div v-else-if="header.value == 'country'" class="text-center">
                                <v-avatar size="24" v-if="item.country">
                                  <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.country.substring(0,2).toLowerCase()}.svg`"></v-img>
                                </v-avatar>
                                {{ item[header.value] }}
                              </div>
                              <div v-else class="text-center">
                                <v-chip v-if="item[header.value]" small class="primaryText--text" outlined @click=" addToFilter(item[header.value], header.value) " style="border: none; font-size: 11px" >
                                  {{ item[header.value] }}
                                </v-chip>
                              </div>
                            </td>
                          </div>
                          <v-divider class="my-0 py-0"></v-divider>
                        </template>
                      </v-virtual-scroll>
                    </div>
                  </v-card-text>
                  <div v-else class="containerMessage">
                    <h3 style="color: grey">No Results Found</h3>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-else>
          <valhalla-loading :height="'90vh'" />
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="pasteSSCC" width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Paste SSCC</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :disabled="!pasteData" icon color="secondary" @click="processPasteData()">
            <v-icon> save </v-icon>
          </v-btn>
          <v-btn text @click="pasteSSCC = false"> X </v-btn>
        </v-toolbar>
        <v-card-text>
          <div v-if="pasteErrors" style="width: 100%">
            <span style="font-size: 12px"
              >Error adding SSCC's: {{ pasteErrors }}
            </span>
          </div>
          <v-textarea
            placeholder="Paste SSCC's here"
            v-model="pasteData"
            outlined
            rows="12"
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  props: ["orders", "refreshKey", 'traderView'],
  components: {
  },
  data: () => ({
    allocated: [],
    allowDualLoad: false,
    containers: [],
    customers: [],
    displayAllocated: false,
    displayData: [],
    drag: false,
    filterPort: null,
    filterCustomer: null,
    filter: {},
    filterKey: 100,
    filteredValues: [],
    headerOptions: {},
    loading: false,
    loadingAutoAllocation: false,
    loadingAllocated: false,
    loadingAllocation: false,
    loadOutKey: 200,
    loadedContainers: [],
    loadingItems: false,
    loadingRemoveAllocation: false,
    loadingStock: false,
    orderKey: 500,
    orderItems: [],
    orderStatus: [
      { name: "Planned", icon: "schedule", color: "#F57C00" },
      { name: "In Progress", icon: "pending", color: "blue" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      // { name: "Cancelled", icon: "cancel", color: "#F44336" }
    ],
    palletStatus: [
      { name: "In Stock", icon: "local_shipping", color: "orange" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "In Transit", icon: "local_shipping", color: "teal" },
      { name: "Rejected", icon: "verified", color: "orange" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Removed", icon: "cancel", color: "grey" },
      { name: "Instruction Sent", icon: "email", color: "indigo" },
    ],
    productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    pasteData: null,
    pasteErrors: null,
    pasteSSCC: false,
    savingLoadOut: false,
    selectedOrder: {},
    selectedContainer: {},
    selectedPallets: [],
    sortBy: null,
    sortDesc: false,
    stock: [],
    stockHeaders: [
      {
        text: "",
        value: "actions",
        width: "50px",
        disableSort: true,
      },
      {
        text: "Location",
        value: "locationCode",
        width: "80px",
        filterable: true,
      },
      {
        text: "Age",
        value: "age",
        width: "50px",
        filterable: true,
      },
      {
        text: "Status",
        value: "status",
        width: "100px",
        filterable: true,
      },
      {
        text: "SSCC",
        value: "sscc",
        width: "150px",
        filterable: true,
      },
      {
        text: "Seq.",
        value: "sequenceNo",
        width: "50px",
      },
      {
        text: "Comm",
        value: "commodityCode",
        filterable: true,
        width: "75px",
      },
      {
        text: "Var",
        value: "varietyCode",
        filterable: true,
        width: "75px",
      },
      {
        text: "Class",
        value: "grade",
        filterable: true,
        width: "50px",
      },
      {
        text: "Pack",
        value: "packCode",
        filterable: true,
        width: "75px",
      },
      {
        text: "Count Size",
        value: "countSize",
        filterable: true,
        width: "75px",
      },
      {
        text: "Mark",
        value: "mark",
        filterable: true,
        width: "75px",
      },
      {
        text: 'Inv Code',
        value: 'inventoryCode',
        filterable: true,
        width: '75px',
      },
      {
        text: "Cartons",
        value: "cartonQuantity",
        filterable: true,
        width: "75px",
      },
      {
        text: "Plt. Qty",
        value: "palletQuantity",
        filterable: true,
        width: "75px",
      },
      {
        text: "PHC",
        value: "phc",
        filterable: true,
        width: "75px",
      },
      {
        text: "PUC",
        value: "puc",
        filterable: true,
        width: "75px",
      },
      {
        text: "Orch.",
        value: "orchard",
        filterable: true,
        width: "75px",
      },
      {
        text: 'Target Market',
        value: 'targetMarket',
        filterable: true,
        width: '100px',
      },
      {
        text: 'Origin Country',
        value: 'country',
        filterable: true,
        width: '100px',
      },
      {
        text: 'Target Country',
        value: 'targetCountry',
        filterable: true,
        width: '100px',
      },
      {
        text: 'Target Region',
        value: 'targetRegion',
        filterable: true,
        width: '100px',
      },

    ],
    searchCustomer: "",
    searchHeaders: {},
    searchKey: 250,
    search: null,
  }),
  watch: {
    allocated: {
      immediate: true,
      handler(val) {
        this.selectedContainer.assignedStock = [
          ...new Set(val.map((x) => x.sscc)),
        ].length;
        this.selectedContainer.assignedProgress = Math.round(
          (this.selectedContainer.assignedStock / 20) * 100
        );
        this.selectedContainer.cartons = val
          .map((x) => x.cartonQuantity)
          .reduce((a, b) => a + b, 0);
      },
    },
    orders: {
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          if (
            this.loadedContainers.length == 0 ||
            !this.loadedContainers.every((x) =>
              val.map((y) => y.id).includes(x)
            )
          ) {
            this.loadContainers(val.map((x) => x.id));
            this.loadedContainers = val.map((x) => x.id);
          }
        }
      },
    },
  },
  computed: {
    averageAge() {
      let result = this.displayData.map((x) => x.age).reduce((a, b) => a + b, 0) / this.displayData.length;
      return Math.round(result * 100) / 100;
    },
    calculateAllocatedPallets() {
      let result = [
        ...new Set(this.displayData.filter((x) => x.bookingContainerId)),
      ].length;
      return result;
    },
    calculateUnallocatedPallets() {
      let result = [
        ...new Set(
          this.displayData
            .filter((x) => !x.bookingContainerId)
            .map((x) => x.sscc)
        ),
      ].length;
      return result;
    },
    calculatedHeight() {
      let height = window.innerHeight;
      return this.traderView ? height * 0.64 + "px" : this.orders.length ==1 ? height * 0.6 + "px": height * 0.5 + "px";
    },
    calculatePallets() {
      let result = [...new Set(this.displayData.map((x) => x.sscc))].length;
      return result;
    },
    calculateCartons() {
      let result = this.displayData
        .map((x) => x.cartonQuantity)
        .reduce((a, b) => a + b, 0);
      return result;
    },
    filterCustomers() {
      let result = [...this.customers];
      if (this.filterPort) {
        let findBookings = result.filter(
          (x) => x.portOfLoadValue == this.filterPort
        );
        let validCustomers = result.filter(
          (x) =>
            x.type == "customer" &&
            findBookings.some((y) => y.orderCustomerId == x.id)
        );
        let validBookings = result.filter(
          (x) => x.type == "order" && findBookings.some((y) => y.id == x.id)
        );
        let validContainers = result.filter(
          (x) =>
            x.type == "container" &&
            findBookings.some((y) => y.id == x.bookingId)
        );
        result = result.filter(
          (x) =>
            validCustomers.includes(x) ||
            validBookings.includes(x) ||
            validContainers.includes(x)
        );
        // result = result.filter(x => findBookings.includes(x.id));
        // result = result.filter(x => x.portOfLoadValue == this.filterPort)
      }
      if (this.filterCustomer) {
        let findBookings = result.filter(
          (x) =>
            x.type == "order" && x.orderCustomerId == this.filterCustomer.id
        );
        let validCustomers = result.filter(
          (x) => x.type == "customer" && findBookings.some((y) => y.id == x.id)
        );
        let validBookings = result.filter(
          (x) => x.type == "order" && findBookings.some((y) => y.id == x.id)
        );
        let validContainers = result.filter(
          (x) =>
            x.type == "container" &&
            findBookings.some((y) => y.id == x.bookingId)
        );
        result = result.filter(
          (x) =>
            validCustomers.includes(x) ||
            validBookings.includes(x) ||
            validContainers.includes(x)
        );
      }
      if (this.searchCustomer) {
        result = result.filter(
          (x) =>
            (x.type == "customer" &&
              x.name
                .toLowerCase()
                .includes(this.searchCustomer.toLowerCase())) ||
            (x.type == "order" &&
              x.orderNo
                .toLowerCase()
                .includes(this.searchCustomer.toLowerCase())) ||
            (x.type == "container" &&
              x.ctoNo.toLowerCase().includes(this.searchCustomer.toLowerCase()))
        );
      }
      return result;
    },
    filterOrders() {
      let result = this.orders;
      if (this.filterPort) {
        result = result.filter((x) => x.portOfLoadValue == this.filterPort);
      }
      if (this.filterCustomer) {
        result = result.filter(
          (x) => x.orderCustomerId == this.filterCustomer.id
        );
      }
      if (this.searchOrder) {
        result = result.filter((x) =>
          x.orderNo.toLowerCase().includes(this.searchOrder.toLowerCase())
        );
      }
      return result;
    },
    selectedCartons() {
      return this.stock
        .filter((x) => this.selectedPallets.includes(x.id))
        .map((x) => parseInt(x.cartonQuantity))
        .reduce((a, b) => a + b, 0);
    },
    selectedSSCC() {
      return [
        ...new Set(
          this.stock
            .filter((x) => this.selectedPallets.includes(x.id))
            .map((x) => x.sscc)
        ),
      ].length;
    },
    tableWidth() {
      let result = 0;
      this.stockHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
  },
  created() {
    this.setFilter();
  },
  methods: {
    addToFilter(value, header) {
      if (this.filter[header].includes(value)) {
        this.filter[header] = this.filter[header].filter((x) => x != value);
      } else {
        this.filter[header].push(value);
      }
      // console.log("Current active filter", this.filter);
      this.filterKey++;
      this.calculateDisplayData();
    },
    autoAllocate() {
      this.loadingAutoAllocation = true;
      let remaining = this.selectedContainer.totalPallets - this.selectedSSCC;
      let sliceResult = [...this.displayData];
      let allowedStores = [];
      let uniqueStores = [
        ...new Set(this.displayData.map((x) => x.locationCode)),
      ];
      let calculatedStores = [];
      for (let i = 0; i < uniqueStores.length; i++) {
        let store = this.displayData.filter(
          (x) => x.locationCode == uniqueStores[i]
        );
        let averageAge =
          store.map((x) => x.age).reduce((a, b) => a + b, 0) / store.length;
        calculatedStores.push({ id: uniqueStores[i], age: averageAge });
      }
      calculatedStores.sort((a, b) => {
        return a.age < b.age ? 1 : -1;
      });
      allowedStores =
        calculatedStores.length > 1
          ? [calculatedStores[0].id, calculatedStores[1].id]
          : calculatedStores.map((x) => x.id);
      if (this.allowDualLoad) {
        sliceResult = sliceResult.filter((x) =>
          allowedStores.includes(x.locationCode)
        );
      } else {
        sliceResult = sliceResult.filter(
          (x) => x.locationCode == allowedStores[0]
        );
      }
      sliceResult.sort((a, b) => {
        return a.age < b.age ? 1 : -1;
      });
      this.selectedPallets = [
        ...this.selectedPallets,
        ...sliceResult.slice(0, remaining).map((x) => x.id),
      ];
      this.loadingAutoAllocation = false;
      this.processAllocation();
    },
    calculateDisplayData() {
      let result = [...this.stock];
      let appliedfilter = Object.keys(this.filter).filter(
        (x) => this.filter[x].length > 0
      );
      this.filteredValues = appliedfilter;
      for (let i = 0; i < appliedfilter.length; i++) {
        if (appliedfilter[i] == "grade") {
          result = result.filter(
            (x) =>
              this.filter[appliedfilter[i]].includes(x.formattedClass) ||
              this.filter[appliedfilter[i]].includes(x.grade)
          );
        } else {
          result = result.filter((x) =>
            this.filter[appliedfilter[i]].includes(x[appliedfilter[i]])
          );
        }
      }
      if (!this.displayAllocated) {
        result = result.filter((x) => !x.bookingContainerId);
      } else {
        result = result.filter(
          (x) => x.bookingContainerId || this.selectedPallets.includes(x.id)
        );
      }
      if (this.search) {
        result = result.filter((x) => x.sscc.includes(this.search));
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      this.displayData = result;

      this.filterKey++;
    },
    changeDisplayAllocated(){
      this.displayAllocated = !this.displayAllocated;
      this.calculateDisplayData();
    },
    changeDualLoadStatus() {
      this.allowDualLoad = !this.allowDualLoad;
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
      this.calculateDisplayData();
    },
    async createLoadOutInstruction() {
      try {
        this.savingLoadOut = true;
        let result = await this.$API.createLoadOutInstructions(
          this.selectedContainer.id
        );
        this.selectedContainer.loadOutInstructions = result;
        let findIndex = this.orderItems.findIndex(
          (x) => x.id == this.selectedContainer.id
        );
        this.orderItems[findIndex].loadOutInstructions = result;
        this.$message.success("Successfully generated load out instruction!");
        this.loadOutKey++;
        this.savingLoadOut = false;
      } catch (e) {
        console.log(e);
        this.savingLoadOut = false;
      }
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.key.split("/").at(-1);
      hiddenElement.click();
    },
    formatBaseUnit(type) {
      let result = this.productQuantityTypes.find((x) => x.value == type);
      return result ? result.text : "";
    },
    async sendLoadOutInstruction() {
      try {
        this.savingLoadOut = true;
        await this.$API.sendLoadOutInstruction(this.selectedContainer.id);
        this.savingLoadOut = false;
      } catch (e) {
        console.log(e);
        this.savingLoadOut = false;
      }
    },
    getPalletStatusColor(status) {
      let find = this.palletStatus.find((x) => x.name == status);
      return find ? find.color : "grey";
    },
    // headerOptions(value) {
    //   let result = [
    //     ...new Set(
    //       this.stock
    //         .filter((x) => x[value])
    //         .map((x) => ({ name: x[value], value: x[value] }))
    //     ),
    //   ];
    //   let uniqueValues = [...new Set(result.map((x) => x.value))];
    //   result = uniqueValues.map((x) => result.find((y) => y.value === x));
    //   return result;
    // },
    setHeaderOptions() {
      for(let i = 0; i < this.stockHeaders.length; i++){
        let value = this.stockHeaders[i].value;
        let result = [
        ...new Set(
          this.stock
            .filter((x) => x[value])
            .map((x) => ({ name: x[value], value: x[value] }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      this.headerOptions[this.stockHeaders[i].value] = result;
      }
     
      // return result;
    },
    itemSelected(id) {
      let result = this.selectedPallets.includes(id);
      return result;
    },
    locationCodeCount(code) {
      return this.allocated.filter((x) => x.locationCode == code).length;
    },
    async loadContainers(orders) {
      this.loading = true;
      this.loadingItems = true;
      this.orderItems = await this.$API.getAllOrderContainersBasic({
        batch: orders,
      });
      this.orderItems.forEach((x) => {
        x.assignedProgress = (x.assignedStock / x.totalPallets) * 100;
      });
      let uniqueCustomers = [
        ...new Set(this.orders.map((x) => x.orderCustomerId)),
      ];
      let data = [];
      uniqueCustomers = uniqueCustomers
        .map((x) => this.orders.find((y) => y.orderCustomerId == x))
        .map((x) => x.orderCustomer)
        .filter(Boolean);
      for (let i = 0; i < uniqueCustomers.length; i++) {
        let findCustomer = this.orders.find(
          (x) => x.orderCustomerId == uniqueCustomers[i].id
        );
        data.push({
          ...findCustomer.orderCustomer,
          type: "customer",
        });
        let orders = this.orders.filter(
          (x) => x.orderCustomerId == uniqueCustomers[i].id
        );
        orders.forEach((x) => {
          let containers = this.orderItems.filter((y) => y.bookingId == x.id);
          data.push({
            ...x,
            type: "order",
          });
          containers.forEach((y) => {
            y.type = "container";
            y.orderCustomerId = uniqueCustomers[i].id;
          });
          data = [...data, ...containers];
        });
      }
      this.customers = data;
      if (this.orderItems.length > 0) {
        this.setActiveContainer(this.orderItems[0]);
      }
      this.loadingItems = false;
      this.loading = false;
    },
    async loadContainerAllocation() {
      this.loadingAllocated = true;
      this.allocated = await this.$API.getContainerStockAllocation(
        this.selectedContainer.id
      );
      this.selectedPallets = this.stock
        .filter((x) => this.allocated.map((y) => y.id).includes(x.id))
        .map((x) => x.id);
      this.loadingAllocated = false;
    },
    async loadOrderStock() {
      this.loading = true;
      let data = await this.$API.getOrderAllocations({
        orders: this.orders.map((x) => x.id),
      });
      this.orderData = data;
      this.loading = false;
    },
    async loadRelatedStock() {
      this.loadingStock = true;
      this.selectedPallets = [];
      let data = await this.$API.getRelatedStock(this.selectedContainer.id);
      this.stock = data;
      this.selectedPallets = this.stock
        .filter((x) => x.bookingContainerId == this.selectedContainer.id)
        .map((x) => x.id);
        this.setHeaderOptions();
      this.calculateDisplayData();
      this.loadingStock = false;
    },
    async processAllocation() {
      try {
        this.loadingAllocation = true;
        let filtered = this.stock.filter((x) =>
          this.selectedPallets.includes(x.id)
        );
        let result = await this.$API.allocateOrder(this.selectedContainer.id, {
          stock: this.selectedPallets,
          orderId: this.selectedContainer.bookingId,
        });
        let findIndex = this.orderItems.findIndex(
          (x) => x.id == this.selectedContainer.id
        );
        this.orderItems[findIndex].assignedStock = [
          ...new Set(result.map((x) => x.sscc)),
        ].length;
        this.orderItems[findIndex].assignedProgress = Math.round(
          (this.orderItems[findIndex].assignedStock /
            this.orderItems[findIndex].totalPallets) *
            100
        );
        this.orderItems[findIndex].cartons = result
          .map((x) => x.cartonQuantity)
          .reduce((a, b) => a + b, 0);
        let remove = this.stock.filter(
          (x) =>
            x.bookingContainerId == this.selectedContainer.id &&
            !this.selectedPallets.includes(x.id)
        );
        remove.forEach((x) => {
          x.bookingContainerId = null;
          x.bookingContainer = null;
          x.status = "In Stock";
        });
        filtered.forEach((x) => {
          x.bookingContainerId = this.selectedContainer.id;
          x.bookingContainer = this.selectedContainer;
          x.status = "Allocated";
        });
        this.loadingAllocation = false;
        this.$message.success("Successfully allocated pallets!");
      } catch (e) {
        console.log(e);
        this.$message.error("An error ocurred!");
        this.loadingAllocation = false;
      }
    },
    processPasteData() {
      let data = this.pasteData.split("\n");
      let pasteErrors = [];
      let count = 0;
      for (let i = 0; i < data.length; i++) {
        let sscc = data[i].trim();
        let pallet = this.stock.filter((x) => x.sscc == sscc);
        if (
          pallet.length > 0 &&
          this.selectedSSCC < (this.selectedContainer.totalPallets || 20) &&
          !this.selectedPallets.includes(pallet.id)
        ) {
          this.selectedPallets = [
            ...pallet.map((x) => x.id),
            ...this.selectedPallets,
          ];
          count += 1;
        } else {
          pasteErrors.push(sscc);
        }
      }
      this.pasteErrors = pasteErrors.join(", ");
      this.$message.success(count + " pallets added!");
    },
    async removeAllocation() {
      this.loadingRemoveAllocation = true;
      await this.updatePalletStatus("In Stock");
      this.selectedPallets = [];
      await this.processAllocation();
      this.loadingRemoveAllocation = false;
    },
    productSpec(item) {
      let keys = [
        { key: "productId", value: "Product" },
        { key: "varietyProductId", value: "Variety" },
        { key: "puc", value: "PUC" },
        { key: "phc", value: "PHC" },
        { key: "packCode", value: "PACK" },
        { key: "grade", value: "Grade" },
        { key: "countSize", value: "Count" },
        { key: "inventoryCode", value: "Inventory Code" },
      ];
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (keys[i].key == "productId" && item.product) {
          result.push(item.product.name);
        } else if (keys[i].key == "varietyProductId" && item.varietyProduct) {
          result.push(item.varietyProduct.name);
        } else if (item[keys[i].key]) {
          result.push(keys[i].value + ": " + item[keys[i].key]);
        }
      }
      return result.join(" - ");
    },
    resetFilters() {
      this.setFilter();
      this.calculateDisplayData();
    },
    selectPallet(item) {
      let findSSCC = this.stock
        .filter((x) => x.sscc == item.sscc)
        .map((x) => x.id);
      if (this.selectedPallets.includes(item.id)) {
        this.selectedPallets = this.selectedPallets.filter(
          (x) => !findSSCC.includes(x)
        );
      } else {
        this.selectedPallets = [...this.selectedPallets, ...findSSCC];
      }
      this.selectedPalletKey++;
    },
    setFilter() {
      let values = this.stockHeaders.map((x) => x.value);
      this.filter = {};
      values.forEach((x) => {
        this.searchHeaders[x] = null;
        this.filter[x] = [];
      });
    },
    setFilterCustomer(customer) {
      this.filterCustomer = customer;
      this.orderKey++;
    },
    setFilterPort(port) {
      this.filterPort = port;
      this.orderKey++;
    },
    setProductFilter(item) {
      this.setFilter();
      let filterableValues = this.stockHeaders.map((x) => x.value);
            for (let value of filterableValues) {
        if (value == "varietyCode" && item.varietyProduct) {
          this.addToFilter(item.varietyProduct.code, value);
        } else if (value == "commodityCode" && item.product) {
          this.addToFilter(item.product.code, value);
        } else if (item[value]) {
          this.addToFilter(item[value], value);
        }
      }
    },
    statusColor(status) {
      if (status) {
        let color = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else return "grey";
    },
    statusIcon(status) {
      if (status) {
        let find = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return "help";
    },
    async setActiveContainer(container) {
      this.selectedContainer = container;
      this.loadOutKey++;
      await this.loadRelatedStock();
    },
    async updatePalletStatus(status) {
      try {
        this.loadingStatus = true;
        await this.$API.updateStockPalletStatus(this.selectedPallets, status);
        this.selectedContainer.status = status;
        let filtered = this.stock.filter((x) =>
          this.selectedPallets.includes(x.id)
        );
        filtered.forEach((x) => {
          x.status = status;
        });
        this.loadingStatus = false;
        this.updateStatusModal = false;
        this.$message.success("Successfully updated pallet status!");
      } catch (e) {
        console.log(e);
        this.loadingStatus = false;
      }
    },
    updateSearchKey() {
      this.searchKey++;
    },
    updateSearchResult() {
      this.calculateDisplayData();
    },
    viewOrder(item) {
      this.$router.push({
        path: "/order/" + item.systemReference,
      });
    },
  },
};
</script>
<style scoped>
.containerMessage {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftSections {
    height: 100%;
    width: 10%;
    min-width: 10%;
    max-width: 10%;
    background:  var(--v-greyDarker-base) !important;
}
.rightSection {
    height: 100%;
    width: 100%;
    min-width: 85%;
    max-width: 100%;
    background: var(--v-greyRaised-base) !important;
}
.listItemLeft {
    min-height: 38px;
    height: 38px;
    padding: 0 0px !important;
}
.listLeft {
    background-color: transparent !important;
}
.v-list-item__avatar {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;

}
.v-list-item__action {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.v-list-item__title {
    font-size: 0.75rem !important;
    line-height: 1.4 !important;
}

.v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}
.table-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: -17px;
  background: #444444;
  color: #fff;
}
</style>
<template>
  <div>
    <div v-if="!loading">
      <v-card
        flat
        style="background-color: var(--v-background-base) !important"
      >
        <!------------------------------------------ TOP BAR WITH QUICK SEARCH ------------------------------------------>
        <v-toolbar
          color="greyBase"
          dark
          :extended="!$vuetify.breakpoint.mobile"
          flat
          extension-height="55"
        >
          <v-toolbar-title>
            <v-btn icon class="mr-2" @click="$router.go(-1)">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-avatar
                  size="24"
                  v-on="on"
                  :color="
                    order.movementType == 'EXPORT'
                      ? 'deep-orange'
                      : order.movementType == 'IMPORT'
                      ? 'blue darken-2'
                      : 'grey darken-2'
                  "
                  class="mx-1"
                >
                  <h6 style="color: white">
                    {{ order.movementType.charAt(0) }}
                  </h6>
                </v-avatar>
              </template>
              <span
                >{{ $Format.capitalizeFirstLetter(order.movementType) }} Booking</span
              >
            </v-tooltip> -->
            <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small class="mx-2" :color="
                            order.movementType == 'EXPORT'
                              ? 'deep-orange'
                              : order.movementType == 'IMPORT'? 'blue darken-2': 'grey darken-3'
                          ">
                              <v-icon small left color="white">import_export</v-icon>
                          <span style="text-transform: none; color: white">
                            {{ order.movementType}}
                            </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="order.movementType = 'EXPORT', saveOrder()">
                              <v-list-item-content>
                                <v-list-item-title>Export</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="order.movementType = 'IMPORT', saveOrder()">
                              <v-list-item-content>
                                <v-list-item-title>Import</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="order.movementType = 'LOCAL', saveOrder()">
                              <v-list-item-content>
                                <v-list-item-title>Local</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
            <b class="mr-3">Manage Order: {{ order.orderNo }}</b>
            <v-menu offset-y rounded>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" small :color="statusColor(order.orderStatus)">
                  <v-icon small left>{{
                    statusIcon(order.orderStatus)
                  }}</v-icon>
                  <span style="text-transform: none; color: white">
                    {{ order.orderStatus }}
                  </span>
                </v-chip>
              </template>
              <v-list dense subheader>
                <v-list-item
                  @click="(order.orderStatus = status.name), saveOrder()"
                  v-for="(status, index) in orderStatus"
                  :key="index"
                >
                  <v-list-item-action>
                    <v-icon :color="status.color">{{ status.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ status.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <v-chip outlined style="border: none" class="ml-2">
            <v-tooltip bottom v-for="(location, index) in fullRoute" :key="index">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-chip pill small outlined class="primaryText--text">
                    <v-avatar size="24" left>
                      <v-img contain v-if="location.locode" :src="`https://cdn.loglive.io/flags/4x3/${location.locode
                        .substring(0, 2)
                        .toLowerCase()}.svg`"></v-img> </v-avatar>{{ location.locode }}
                  </v-chip>

                  <v-icon class="mx-3" color="grey" small v-if="index < fullRoute.length - 1">east</v-icon>
                </div>
              </template>
              <span style="font-size: 12px">{{ location.type }}: {{ location.locode }}</span>
            </v-tooltip>
          </v-chip> -->
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div
            v-if="!$vuetify.breakpoint.mobile"
            :key="userUpdateKey"
            class="mr-2"
          >
            <v-tooltip top v-for="user in activeUsers" :key="user.connectionId">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  style="cursor: pointer"
                  color="secondary"
                  size="48"
                  class="mt-0 pt-0 mx-1"
                >
                  <span
                    v-if="!user.user.avatar"
                    class="primaryText--text text-h5"
                    >{{$Format.avatarAbbreviation(user.user.name) }}</span
                  >
                  <img
                    v-else
                    :src="user.user.avatar"
                    referrerpolicy="no-referrer"
                  />
                </v-avatar>
              </template>
              <span style="font-size: 12px">{{ user.user.name }}</span>
            </v-tooltip>
          </div>
          <v-spacer></v-spacer>
          <v-btn-toggle
          v-if="!$vuetify.breakpoint.mobile"
          mandatory
          outlined
          :light="!$store.state.darkMode"
          v-model="tab"
          rounded
          color="blue"
        >
          <v-btn small outlined>
            <v-icon v-if="!$vuetify.breakpoint.mobile" left small :color="tab == 0 ? 'blue' : 'primaryText'"
              >info</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Details</span>
          </v-btn>
         
          <v-btn small outlined>
            <v-icon v-if="!$vuetify.breakpoint.mobile" left small :color="tab == 1 ? 'blue' : 'primaryText'"
              >paid</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Financials</span>
          </v-btn>
          <!-- <v-btn small outlined>
            <v-icon v-if="!$vuetify.breakpoint.mobile" left small :color="tab == 2 ? 'blue' : 'primaryText'"
              >widgets</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Containers</span>
          </v-btn> -->
          </v-btn-toggle>
        </v-toolbar>
        <!------------------------------------------ MAIN BODY ------------------------------------------>
        <v-card
          class="mx-auto"
          :loading="savingOrder"
          :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
          :style="{
            'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
            'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
          }"
        >
          <v-card-text
            :style="{
              height: !$vuetify.breakpoint.mobile ? '92vh' : '90vh',
              'overflow-y': 'auto',
            }"
          >
          <!-- <v-tabs v-model="tab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab>Details</v-tab>
            <v-tab>Financials</v-tab>
          </v-tabs> -->
          <div v-if="tab==0">
            <v-row class="mt-1">
              <v-col cols="12" sm="4">
                <OrderRelationship
                  :order="order"
                  :countries="countries"
                  :containers="containers"
                  :related="related"
                  :loadingRelatedOrganisations="loadingRelatedOrganisations"
                  @orderChange="saveOrder()"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <OrderLocation
                  :order="order"
                  :countries="countries"
                  @orderChange="saveOrder()"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <OrderItem
                  :order="order"
                  :containers="stock"
                  :loading="loadingContainers"
                  :stock="stock"
                  :related="related"
                  @refreshStock="getStock()"
                />
              </v-col>
            </v-row>
          </div>
          <!-- <div v-else-if="tab==2">
            
            <OrderItemCards :order="order" :containers="stock" > </OrderItemCards>
          </div> -->
          <div v-else-if="tab==1">
            
            <OrderFinancials :order="order" :containers="stock" :organisations="related" :loadingOrganisations="loadingRelatedOrganisations"/>
          </div>
            
          </v-card-text>
        </v-card>
      </v-card>
    </div>

    <div
      v-else
      style="height: 90vh; padding-top: 0; margin-top: 0"
      class="text-center"
    >
      <valhalla-loading />
    </div>
  </div>
</template>
<script>
import * as moment from "moment";
import OrderFinancials from "./OrderFinancials.vue";
import OrderItem from "./OrderItems.vue";
// import OrderItemCards from "./OrderItemCards.vue";
import OrderLocation from "./OrderLocations.vue";
import OrderRelationship from "./OrderRelationship.vue";

export default {
  components: {
    OrderFinancials,
    OrderItem,
    // OrderItemCards,
    OrderLocation,
    OrderRelationship,
  },
  data: () => ({
    activeUsers: [],
    containers: [],
    containerTableKey: 200,
    countries: [],
    loading: false,
    loadingContainers: false,
    loadingCountry: false,
    loadingRelatedOrganisations: false,
    loadingShipmentProfiles: false,
    order: {},
    orderStatus: [
      { name: "Planned", icon: "schedule", color: "#F57C00" },
      { name: "In Progress", icon: "pending", color: "blue" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" }
    ],

    related: {
      data: [],
    },
    savingOrder: false,
    shipmentProfiles: {
      data: [],
    },
    stock: [],
    tab: 0,
    userUpdateKey: 100,
  }),
  watch: {
    "$route.params.systemReference": {
      immediate: true,
      handler(val) {
        if (val) {
          this.tab = 0;
          this.getOrder(val);
        }
      },
    },
  },
  created() {
    this.loadCountries();
    this.getRelations();
  },
  methods: {


    async getContainers() {
      this.loadingContainers = true;
      this.containers = [];
      this.containerTableKey++;
      let containers = await this.$API.getOrderContainers(this.order.id);
      this.containers = containers;
      this.loadingContainers = false;
    },
    async getOrder(systemReference) {
      this.loading = true;
      this.order = await this.$API.getOrderDetail(systemReference);
      setTimeout(() => {
        this.$socket.send(
          JSON.stringify({
            action: "join",
            room: "order_" + this.order.id,
            user: {
              id: this.$store.state.user.id,
              name:
                this.$store.state.user.firstname +
                " " +
                this.$store.state.user.surname,
              avatar: this.$store.state.user.avatar,
              company: this.$store.state.currentOrg.name,
            },
          })
        );
      }, 1000);
      this.$socket.onopen = () => {
        this.$socket.send(
          JSON.stringify({
            action: "join",
            room: "order_" + this.order.id,
            user: {
              id: this.$store.state.user.id,
              name:
                this.$store.state.user.firstname +
                " " +
                this.$store.state.user.surname,
              avatar: this.$store.state.user.avatar,
              company: this.$store.state.currentOrg.name,
            },
          })
        );
      };
      this.$socket.onmessage = (data) => {
        let message = JSON.parse(data.data);
        if (this.order && this.order.id) {
          if (
            message.type == "users" &&
            message.room == "order_" + this.order.id
          ) {
            this.activeUsers = message.data;
            this.userUpdateKey++;
          }
        }
      };
      //   this.getComments();
      if (this.order.orderCustomerId) {
        this.getShipmentProfiles();
      }
      // this.getContainers();
      this.getStock();
      if (this.order.consigneeProfileId) {
        this.order.consigneeProfile = await this.$API.getConsigneeProfile(
          this.order.consigneeProfileId
        );
      }
      this.loading = false;
    },
    // async getRelations() {
    //   this.loadingRelatedOrganisations = true;
    //   this.related = await this.$API.getRelation({});
    //   this.loadingRelatedOrganisations = false;
    // },
    async getRelations() {
      this.loadingRelatedOrganisations = true;
      if (
        localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id]
      ) {
        this.related = JSON.parse(
          localStorage[
            "relatedOrganisations_" + this.$store.state.currentOrg.id
          ]
        );
      }
      this.related = await this.$API.getRelationBasic({
      });
      let findCurrentOrg = this.related.data.find(
        (x) => x.relatedOrganisationId == this.$store.state.currentOrg.id
      );
      if (!findCurrentOrg) {
        this.related.data.unshift({relatedOrganisationId: this.$store.state.currentOrg.id, 
          relatedOrganisation: this.$store.state.currentOrg
        });
      }
      localStorage.setItem(
        "relatedOrganisations_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.related)
      );
     this.loadingRelatedOrganisations = false;
    },
    async getStock() {
      this.stock = await this.$API.getOrderContainersBasic(this.order.id);
      this.stock.forEach((x) => {
        x.assignedProgress = (x.assignedStock / x.totalPallets) * 100;
      });
    },
    async getShipmentProfiles() {
      this.loadingShipmentProfiles = true;
      this.shipmentProfiles = await this.$API.listCustomerConsigneeProfile(
        this.order.orderCustomerId,
        {}
      );
      this.loadingShipmentProfiles = false;
    },
    async loadCountries() {
      this.loadingCountry = true;
      this.countries = await this.$API.searchCountriesBasic({});
      this.loadingCountry = false;
    },
    async saveOrder() {
      try {
        this.savingOrder = true;
        this.order.numContainers = this.containers.length;
        if (this.order.orderStatus == "Planned") {
          this.order.etd = moment()
            .day("Monday")
            .year(this.order.plannedYear)
            .week(this.order.plannedWeek);
        }
        await this.$API.updateOrder(this.order);
        if (this.order.forwarderId == 2507 || this.order.forwarderId == 51625) {
          this.order.allowBooking = true;
        }
        this.savingOrder = false;
        this.$message.success("Successfully updated order!");
      } catch (e) {
        this.savingOrder = false;
        this.$message.error(e.message);
      }
    },
    statusColor(status) {
    if(status){
      let color = this.orderStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return color ? color.color : "grey";
    } else return 'grey'      
    },
    statusIcon(status) {
      if(status){
      let find = this.orderStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return find ? find.icon : "";
      } else return 'help'
    },
  },
};
</script>
<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          <span v-if="invoice.id">Edit</span>
            <span v-else>Add</span>
          Invoice 
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip small class="mx-1" v-if="invoice.totalExclVAT">
         Total Ex VAT: {{ getSymbol(invoice.currencyCode) }}{{ formatNumber(invoice.totalExclVAT) }}
        </v-chip>
        <v-chip small class="mx-1" v-if="invoice.totalInclVAT">
         Total: {{ getSymbol(invoice.currencyCode) }}{{ formatNumber(invoice.totalInclVAT) }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn v-if="!invoice.id" icon color="primary" :loading="savingInvoice" @click="saveInvoice()" :disabled="!invoice.customerId || !invoice.contactId || invoice.invoiceLineItems.length == 0 || invoice.invoiceLineItems.some((x) => !x.code )">
          <v-icon>save</v-icon>
        </v-btn>
        <v-btn v-if="invoice.id && editing" icon color="primary" :loading="savingInvoice" @click="checkUpdateType()" :disabled="!invoice.customerId || !invoice.contactId || invoice.invoiceLineItems.length == 0">
          <v-icon>save</v-icon>
        </v-btn>
        <v-btn icon color="teal" @click="viewInvoice()" v-if="invoice.invoiceURL">
          <v-icon>launch</v-icon>
        </v-btn>
        <v-btn text @click="$emit('close')"> X</v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- <v-container> -->
          <v-row class="my-2">
            <v-col cols="12" sm="6" md="4" class="my-0 py-0">
              <v-autocomplete
                label="Customer*"
                :items="organisations.data"
                item-text="relatedOrganisation.name"
                item-value="relatedOrganisation.id"
                hide-details
                outlined
                v-model="invoice.customerId"
                @change="setAccountingContactId()"
                clearable
                dense
              >
                <template v-slot:selection="data">
                  <v-row align="center">
                    <v-avatar
                      size="32"
                      :color="
                        data.item && data.item.relatedOrganisation.logo
                          ? 'white'
                          : 'secondary'
                      "
                      class="mx-2"
                      v-if="data.item"
                    >
                      <v-img
                        v-if="data.item.relatedOrganisation.logo"
                        contain
                        :src="data.item.relatedOrganisation.logo"
                      ></v-img>
                      <h3 v-else>
                        {{ data.item.relatedOrganisation.name.charAt(0) }}
                      </h3>
                    </v-avatar>
                    <span v-if="data.item.relatedOrganisation">
                      {{ data.item.relatedOrganisation.name }}
                    </span>
                  </v-row>
                </template>
                <template v-slot:item="data">
                  <v-list-item-action>
                    <v-avatar
                      size="32"
                      :color="
                        data.item.relatedOrganisation.logo
                          ? 'white'
                          : 'secondary'
                      "
                    >
                      <v-img
                        contain
                        v-if="data.item.relatedOrganisation.logo"
                        :src="data.item.relatedOrganisation.logo"
                      ></v-img>
                      <h3 v-else>
                        {{ data.item.relatedOrganisation.name.charAt(0) }}
                      </h3>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      data.item.relatedOrganisation.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      v-if="data.item.relatedOrganisation.alias"
                      >{{
                        data.item.relatedOrganisation.alias
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
                <template v-slot:append>
                  <v-tooltip top v-if="invoice.customerId && invoice.contactId">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="success"
                        >check_circle_outline</v-icon
                      >
                    </template>
                    <span>Contact linked to accounting</span>
                  </v-tooltip>

                  <v-tooltip
                    top
                    v-else-if="invoice.customerId && !invoice.contactId"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">error</v-icon>
                    </template>
                    <span>Contact not linked to accounting</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="my-0 py-0">
              <v-text-field
                label="Reference"
                v-model="invoice.reference"
                outlined
                dense
                clearable
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="my-0 py-0">
            </v-col>
            <v-col cols="12" sm="6" md="4" class="mb-0 pb-0" >
              <v-select v-if="invoice.id"
                label="Status"
                :items="[ 'DRAFT', 'SUBMITTED','AUTHORISED','DELETED']"
                v-model="invoice.status"
                outlined
                dense
                clearable
                hide-details
              >
              </v-select>
              <v-select v-else
                label="Status"
                :items="[ 'DRAFT', 'SUBMITTED','AUTHORISED']"
                v-model="invoice.status"
                outlined
                dense
                clearable
                hide-details
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="mb-0 pb-0">
              <v-autocomplete :items="currencies" label="Currency" v-model="invoice.currencyCode" outlined dense></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="mb-0 pb-0">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on" class="text-left" style="height: 40px">
                    <v-list-item-icon>
                      <v-icon color="primary">event</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        {{ formatDate(invoice.date) }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 13px">
                        Date
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-date-picker v-model="invoice.date" no-title scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="mb-0 pb-0">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on" class="text-left" style="height: 40px">
                    <v-list-item-icon>
                      <v-icon color="primary">event</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        {{ formatDate(invoice.dueDate) }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 13px">
                        Due Date
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-date-picker v-model="invoice.dueDate" no-title scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <!-- <v-divider></v-divider> -->
              <v-subheader style="font-size: 16px" class="my-0 py-0">
                <v-icon small class="mr-2" color="grey">list</v-icon>
                Line Items
                <v-btn icon color="primary" @click="addLineItem()">
                  <v-icon>add_circle_outline</v-icon>
                </v-btn>
              </v-subheader>
              <v-data-table
                :headers="invoiceHeaders"
                dense
                :items="invoice.invoiceLineItems"
               
              >
              <template v-slot:[`item.action`]="{ item }">
                <v-container>
                  <v-row justify="center">
                      <v-btn icon @click="editLineItem(item)">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                      <v-btn icon color="red" @click="deleteLineItem(item)">
                        <v-icon small>delete</v-icon>
                      </v-btn>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:[`item.type`]="{ item }">
              <v-select
                  :items="['ACCOUNT', 'ITEM']"
                  style="font-size: 12px"
                  v-model="item.type"
                  outlined
                  dense
                  hide-details
                ></v-select>
                </template>
              <template v-slot:[`item.code`]="{ item }">
                <v-autocomplete
                  style="font-size: 12px"
                  :items="filterTableItems(item.type)"
                  v-model="item.code"
                  item-value="code"
                  item-text="name"
                  outlined
                  dense
                  hide-details
                ></v-autocomplete>
</template>
              
              <template v-slot:[`item.description`]="{ item }">
                <div v-html="item.description" style="font-size: 11px"></div>
</template>
              <template v-slot:[`item.taxType`]="{ item }">
                <div v-if="item.taxType">{{ formatTaxType(item.taxType) }}</div>
</template>
<template v-slot:[`item.unitAmount`]="{ item }">
  {{getSymbol(invoice.currencyCode)}}{{formatNumber(item.unitAmount)}}
</template>
<template v-slot:[`item.totalExclVAT`]="{ item }">
  {{getSymbol(invoice.currencyCode)}}{{formatNumber(item.totalExclVAT)}}

</template>
<template v-slot:[`item.totalInclVAT`]="{ item }">
  {{getSymbol(invoice.currencyCode)}}{{formatNumber(item.totalInclVAT)}}

</template>
              </v-data-table>
            </v-col>
          </v-row>
        <!-- </v-container> -->
      </v-card-text>
    </v-card>

    <v-dialog v-model="lineItemModal" width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Add Line Item</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            @click="saveLineItem"
            :disabled="
              !lineItem.code || !lineItem.quantity || !lineItem.unitAmount
            "
          >
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn text @click="lineItemModal = false"> X </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="my-0 py-0">
                <v-select
                  label="Type"
                  :items="['ACCOUNT', 'ITEM']"
                  class="my-2"
                  v-model="lineItem.type"
                  outlined
                  dense
                  clearable
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-autocomplete
                  v-if="lineItem.type == 'ACCOUNT'"
                  label="Code*"
                  class="my-2"
                  :items="filterAccountItems"
                  v-model="lineItem.code"
                  item-value="code"
                  item-text="name"
                  @change="setDescription"
                  outlined
                  dense
                  clearable
                  hide-details
                ></v-autocomplete>
                <v-autocomplete
                  v-else
                  label="Code*"
                  class="my-2"
                  :items="filterItems"
                  v-model="lineItem.code"
                  item-value="code"
                  item-text="name"
                  @change="setDescription"
                  outlined
                  dense
                  clearable
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-textarea
                  label="Description"
                  v-model="lineItem.description"
                  outlined
                  dense
                  clearable
                  hide-details
                  auto-grow
                  rows="4"
                  class="my-2"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-text-field
                  label="Quantity"
                  v-model="lineItem.quantity"
                  type="number"
                  outlined
                  dense
                  clearable
                  hide-details
                  class="my-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-text-field
                  label="Unit Price"
                  :prefix="getSymbol(invoice.currencyCode)"
                  v-model="lineItem.unitAmount"
                  outlined
                  dense
                  clearable
                  hide-details
                  class="my-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-select
                  label="Tax Type"
                  :items="taxTypes"
                  item-text="name"
                  item-value="code"
                  v-model="lineItem.taxType"
                  outlined
                  dense
                  clearable
                  hide-details
                  class="my-2"
                ></v-select>
              </v-col>
              <!-- <v-col cols="12" class="my-0 py-0">
                  <v-text-field
                    label="Total Ex VAT"
                    v-model="lineItem.totalExclVAT"
                    outlined
                    dense
                    clearable
                    hide-details
                    class="my-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <v-text-field
                    label="Total"
                    v-model="lineItem.totalInclVAT"
                    outlined
                    dense
                    clearable
                    hide-details
                    class="my-2"
                  ></v-text-field>
                </v-col> -->
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="promptDeletionCheck" width="500px" persistent>
      <v-card>
        <v-card-title>
          Are you sure you want to delete this invoice?
        </v-card-title>
        <v-card-subtitle class="text-center">
          Selecting Yes will permanently delete this invoice from LogLive and Xero
        </v-card-subtitle>
        <v-card-actions class="justify-center align-center">
          <v-btn color="red" @click="updateInvoice">
            Yes
          </v-btn>
          <v-btn color="grey" @click="promptDeletionCheck = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as CurrencyCodes from "currency-codes";
import getSymbolFromCurrency from "currency-symbol-map";
import dateFormat from "dateformat";
export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    organisations: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
    },
  },
  data: () => ({
    accountItems: [],
    accountContacts: [],
    currencies: CurrencyCodes.codes(),
    invoiceHeaders: [
      {text: "Action", value: "action", align: "center", sortable: false, width: '120px'},
      { text: "Type", value: "type", sortable: false, width: '180px' },
      { text: "Code*", value: "code", sortable: false },
      { text: "Description", value: "description", sortable: false },
      { text: "Quantity", value: "quantity", align: "center", sortable: false },
      { text: "Unit Price", value: "unitAmount", align: "end", sortable: false },
      { text: "Tax Type", value: "taxType", sortable: false },
      { text: "Total Ex VAT", value: "totalExclVAT", align: "end", sortable: false },
      { text: "Total", value: "totalInclVAT", align: "end", sortable: false },
    ],
    lineItem: {},
    lineItemModal: false,
    savingInvoice: false,
    taxTypes: [],
    promptDeletionCheck: false,
  }),
  mounted() {
    this.getAccountItems();
    this.getAccountContacts();
    this.getTaxTypes();
  },
  watch: {
    "invoice" : {
      immediate:true,
      handler (val) {
        this.setAccountingContactId()
      }
    },
    // invoice: {
    //   handler: function (val) {
    //     // this.setAccountingContactId();
    //     console.log(val);
    //   },
    //   deep: true,
    // },
  },
  async created() {

  },
  computed: {
    filterAccountItems() {
      return this.accountItems.filter((item) => item.recordType === "ACCOUNT" && (item.type == "REVENUE" || item.type == "SALES"));
    },
    filterItems() {
      return this.accountItems.filter((item) => item.recordType === "ITEM");
    },
  },
  methods: {
    logItem(item) {
      console.log(item)
    },
    addLineItem() {
      this.lineItem = {
        type: "ACCOUNT",
        description: null,
        quantity: 1,
        unitAmount: 0,
        taxType: "OUTPUT3",
        totalExclVAT: 0,
        totalInclVAT: 0,
        tempId: new Date().getTime()
      };
      this.lineItemModal = true;
    },
    deleteLineItem(item){
      let find = this.invoice.invoiceLineItems.find((x) => x.tempId === item.tempId);
      if(find.orderSalesItemId) {
        let associatedOrderSalesItemIndex = this.invoice.orderSalesItems.indexOf(find.orderSalesItemId)
        this.invoice.orderSalesItems.splice(associatedOrderSalesItemIndex, 1)
      }
      let index = this.invoice.invoiceLineItems.indexOf(find);
      this.invoice.invoiceLineItems.splice(index, 1);
      this.invoice.totalExclVAT = parseFloat((this.invoice.invoiceLineItems.reduce((acc, item) => acc + Number(item.totalExclVAT), 0)).toFixed(2));
      this.invoice.totalInclVAT = parseFloat((this.invoice.invoiceLineItems.reduce((acc, item) => acc + Number(item.totalInclVAT), 0)).toFixed(2));
    },
    editLineItem(item){
      this.lineItem = item;
      this.lineItemModal = true;
    },
    filterTableItems(type) {
      if (type == "ACCOUNT") {
        return this.filterAccountItems;
      } else {
        return this.filterItems;
      }
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatNumber(x, isCost = false) {
      if (isCost) {
        x *= -1;
      }
      if (x) {
        let result = (parseFloat(x).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return result;
      } else {
        return null;
      }
    },
    formatTaxType(code) {
      let find = this.taxTypes.find((tax) => tax.code === code);
      return find ? find.name : null;
    },
    async getAccountItems() {
      try {
        this.accountItems = await this.$API.getAccountingItems();
      } catch (error) {
        console.error(error);
      }
    },
    async getAccountContacts() {
      try {
        this.accountContacts = await this.$API.getAccountingContacts();
      } catch (error) {
        console.error(error);
      }
    },
    getSymbol(currencyCode) {
      return getSymbolFromCurrency(currencyCode);
    },
    async getTaxTypes() {
      try {
        this.taxTypes = await this.$API.getAccountingTaxTypes();
      } catch (error) {
        console.error(error);
      }
    },
    saveLineItem() {
      this.lineItem.totalExclVAT = parseFloat((this.lineItem.quantity * this.lineItem.unitAmount).toFixed(2));
      let findTax = this.taxTypes.find((tax) => tax.code === this.lineItem.taxType);
      this.lineItem.totalInclVAT = parseFloat((this.lineItem.totalExclVAT + (this.lineItem.totalExclVAT * findTax.rate) / 100).toFixed(2));
      let findExisting = this.invoice.invoiceLineItems.find((item) => item.tempId === this.lineItem.tempId);
      if (findExisting) {
        let index = this.invoice.invoiceLineItems.indexOf(findExisting);
        this.invoice.invoiceLineItems.splice(index, 1, this.lineItem);
      } else{
        this.invoice.invoiceLineItems.push(this.lineItem);
      }
      this.invoice.totalExclVAT = parseFloat((this.invoice.invoiceLineItems.reduce((acc, item) => acc + Number(item.totalExclVAT), 0)).toFixed(2));
      this.invoice.totalInclVAT = parseFloat((this.invoice.invoiceLineItems.reduce((acc, item) => acc + Number(item.totalInclVAT), 0)).toFixed(2));
      this.lineItemModal = false;
    },
    async saveInvoice() {
      try {
        this.savingInvoice = true;
        let result = await this.$API.createInvoice(this.invoice);
        if(result && result.id){
          this.$message.success("Invoice created successfully");
          this.savingInvoice = false
          this.$emit('invoice-created', result);
          this.$emit("close");
        }
      } catch (error) {
        console.error(error);
        if(error.message){
          this.$message.error(error.message);
        } else {
          this.$message.error("Failed to create invoice");
        }
        this.savingInvoice = false
      }
    },
    checkUpdateType() {
      if(this.invoice.status === "DELETED") {
        this.$confirm("Are you sure you want to delete this invoice?", "Confirm", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                    
                    this.updateInvoice()
                })
      }else {
        this.updateInvoice()
      }
    },
    async updateInvoice() {
      try {
        this.savingInvoice = true;
        let result = await this.$API.updateInvoice(this.invoice);
        if(result && result.id){
          if(result.status === 'DELETED') {
            this.$message.success("Invoice deleted successfully");
          } else {
            this.$message.success("Invoice updated successfully");
          }
          this.savingInvoice = false
          this.$emit('invoice-updated', result);
          this.$emit("close");
        }
      } catch (error) {
        console.error(error);
        if(error.message){
          this.$message.error(error.message);
        } else {
          this.$message.error("Failed to update invoice");
        }
        this.savingInvoice = false
      }
    },
    setAccountingContactId() {
      if (this.invoice.customerId) {
        let findRelationship = this.organisations.data.find(
          (x) => x.relatedOrganisationId == this.invoice.customerId
        );
        if (findRelationship) {
          this.invoice.contactId = findRelationship.accountingId;
        } else {
          this.invoice.contactId = null;
        }
      } else {
        this.invoice.contactId = null;
      }
    },
    setDescription(val) {
      if(!this.lineItem.description){
        if (val) {
        let find = this.accountItems.find(
          (item) => item.code === val && item.recordType === this.lineItem.type
        );
        this.lineItem.description = find.name;
      } else {
        this.lineItem.description = null;
      }
      }
      
    },
    viewInvoice(){
      window.open(this.invoice.invoiceURL, "_blank")
    }
  },
};
</script>
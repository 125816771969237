<template>
  <div>
            <v-card
              outlined
              style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              "
            >
              <v-card-text class="my-0 py-0">
                <v-list subheader dense>
                          <v-subheader style="font-size: 16px">
                            <v-icon color="grey" class="mr-2">tag</v-icon>
                            References
                          </v-subheader>
                          <v-divider></v-divider>
                          <v-list-item :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandlerId !=null"
                            @click="
                              editReference(
                                'carrierReferenceNumber',
                                'Carrier Reference'
                              )
                            "
                            ><v-list-item-content>
                              <v-list-item-title>
                                {{ order.carrierReferenceNumber ?? "-" }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Carrier Reference No
                              </v-list-item-subtitle>
                            </v-list-item-content></v-list-item
                          >
                          <v-list-item
                            class="mt-0 pt-0"
                            @click="
                              editReference(
                                'shipperReferenceNo',
                                'Shipper Reference'
                              )
                            "
                            ><v-list-item-content>
                              <v-list-item-title>
                                {{ order.shipperReferenceNo ?? "-" }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Shipper Ref No
                              </v-list-item-subtitle>
                            </v-list-item-content></v-list-item
                          >
                          <v-list-item
                            class="mt-0 pt-0"
                            @click="
                              editReference(
                                'consigneeReferenceNo',
                                'Consignee Reference'
                              )
                            "
                            ><v-list-item-content>
                              <v-list-item-title>
                                {{ order.consigneeReferenceNo ?? "-" }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Consignee Ref No
                              </v-list-item-subtitle>
                            </v-list-item-content></v-list-item
                          >
                          <v-divider></v-divider>
                    <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="grey">thermostat</v-icon> Regime Code</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-if="order.regime"  @click="getRegimeCodes(true)">
                        <v-list-item-action class="mr-0 pr-0">
                          <v-btn text rounded
                              > {{
                                    order.regime.code
                                  }}</v-btn>
                                  <!-- <br> -->
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-subtitle>
                            <v-icon color="#007d5c" small class="mr-2"
                                  >thermostat</v-icon
                                > Set Temp: {{`${order.regime.setPointTemp}°C`}}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle  v-if="order.regime.protocol">
                            <v-icon color="#9d904a" small class="mr-2"
                                  >thermostat</v-icon
                                > Protocol: {{`${order.regime.protocol}°C`}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-else @click="getRegimeCodes(true)">
                        <v-list-item-content class="text-center">
                          <v-list-item-title style="color: grey">
                            No Regime Code
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                        </v-list>
                <v-list dense subheader>
                  <v-divider></v-divider>

                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">groups</v-icon>
                    Parties</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item v-if="order.orderCustomer">
                    <v-list-item-avatar
                      :color="order.orderCustomer.logo ? 'white' : 'secondary'"
                    >
                      <v-img
                        v-if="order.orderCustomer.logo"
                        :src="order.orderCustomer.logo"
                        contain
                      ></v-img>
                      <!-- </v-avatar> -->
                      <!-- <v-avatar v-else color="secondary"> -->
                      <h2 v-else>
                        {{ order.orderCustomer.name.charAt(0) }}
                      </h2>
                      <!-- </v-avatar> -->
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ order.orderCustomer.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Selected Customer
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center">
                        <v-btn small @click="customerSearchModal = true" icon
                          ><v-icon small>edit</v-icon></v-btn
                        >
                        <v-btn small @click="removeCustomer()" color="red" icon
                          ><v-icon small>delete</v-icon></v-btn
                        >
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-else @click="customerSearchModal = true">
                    <v-list-item-action>
                      <v-icon color="primary">add_circle_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <span style="font-size: 16px">Add Customer</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="order.orderCustomer" :key="profileKey">
                    <v-list-item-action>
                      <v-icon color="secondary">handshake</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="order.consigneeProfile">
                        {{ order.consigneeProfile.systemReference }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle>
                        Shipment Profile
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center" v-if="order.consigneeProfile">
                        <v-btn small @click="shipmentProfileDialog = true" icon
                          ><v-icon small>edit</v-icon></v-btn
                        >
                        <v-btn small @click="removeProfile()" color="red" icon
                          ><v-icon small>delete</v-icon></v-btn
                        >
                      </v-row>
                      <v-btn
                        icon
                        color="primary"
                        v-else
                        @click="shipmentProfileDialog = true"
                      >
                        <v-icon>add_circle_outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item
                    v-if="order.consigneeProfile"
                    @click="shipmentProfileConfig = true"
                  >
                    <v-list-item-action>
                      <v-icon v-if="order.dealTerm" color="secondary"
                        >settings</v-icon
                      >
                      <v-icon v-else color="primary">add_circle_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content v-if="!order.dealTerm">
                      <span style="font-size: 16px"
                        >Select Profile Inco Term</span
                      >
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title>
                        {{ order.dealTerm.incoTerm }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Profile Inco Term
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="incoTermModal = true"
                    v-if="!order.consigneeProfile"
                  >
                    <v-list-item-action>
                      <v-icon :color="!order.incoTerm ? 'secondary' : 'grey'"
                        >swap_horiz</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="order.incoTerm">
                        {{ order.incoTerm }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle> Inco Term </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <div v-if="order.orderCustomer">
                    <v-list-item
                      :disabled="
                        order.consigneeProfile != undefined ||
                        order.consigneeProfile != null
                      "
                      @click="(partyModal = true), (partyType = 'Shipper')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >directions_boat_filled</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="order.shipper">
                          {{ order.shipper.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle v-if="order.onBehalfShipper">
                          On behalf of
                          {{ order.onBehalfShipper.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Shipper
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      :disabled="
                        order.consigneeProfile != undefined ||
                        order.consigneeProfile != null
                      "
                      @click="(partyModal = true), (partyType = 'Forwarder')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >fast_forward</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="order.forwarder">
                          {{ order.forwarder.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle v-if="order.onBehalfForwarder">
                          On behalf of
                          {{ order.onBehalfForwarder.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Forwarder
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      :disabled="
                        order.consigneeProfile != undefined ||
                        order.consigneeProfile != null
                      "
                      @click="(partyModal = true), (partyType = 'Consignee')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >call_received</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="order.consignee">
                          {{ order.consignee.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle v-if="order.onBehalfConsignee">
                          On behalf of
                          {{ order.onBehalfConsignee.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Consignee
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="!order.consigneeProfile || order.buyer"
                      :disabled="
                        order.consigneeProfile != undefined ||
                        order.consigneeProfile != null
                      "
                      @click="(partyModal = true), (partyType = 'Buyer')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >shopping_cart</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="order.buyer">
                          {{ order.buyer.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle v-if="order.onBehalfBuyer">
                          On behalf of {{ order.onBehalfBuyer.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Buyer
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="!order.consigneeProfile || order.firstNotify"
                      :disabled="
                        order.consigneeProfile != undefined ||
                        order.consigneeProfile != null
                      "
                      @click="(partyModal = true), (partyType = 'First Notify')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >notifications</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="order.firstNotify">
                          {{ order.firstNotify.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Notify Party
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="!order.consigneeProfile || order.secondNotify"
                      :disabled="
                        order.consigneeProfile != undefined ||
                        order.consigneeProfile != null
                      "
                      @click="
                        (partyModal = true), (partyType = 'Second Notify')
                      "
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >notifications</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="order.secondNotify">
                          {{ order.secondNotify.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          2nd Notify Party
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="!order.consigneeProfile || order.courierParty"
                      :disabled="
                        order.consigneeProfile != undefined ||
                        order.consigneeProfile != null
                      "
                      @click="
                        (partyModal = true), (partyType = 'Courier Party')
                      "
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >local_post_office</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="order.courierParty">
                          {{ order.courierParty.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Courier Party
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list>
              </v-card-text>
            </v-card>
         
    <v-dialog
      v-model="incoTermModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Inco Term </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="incoTermModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="code in incoTerms"
              :key="code.id"
              @click="setIncoTerm(code)"
              style="height: 45px"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ code.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ code.abbreviation }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Payment modal -->
    <v-dialog
      v-model="paymentModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Payment Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            icon
            :disabled="
              !paymentItem.chargeType ||
              !paymentItem.paymentTerm ||
              !paymentItem.paymentParty
            "
            :loading="savingPaymentItem"
            @click="savePaymentItem"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="(paymentModal = false), (paymentItem = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-select
            v-model="paymentItem.chargeType"
            outlined
            dense
            label="Charge Type*"
            item-text="value"
            item-value="key"
            :items="paymentTypes"
          ></v-select>
          <v-select
            v-model="paymentItem.paymentTerm"
            outlined
            dense
            label="Payment Term*"
            item-text="value"
            item-value="key"
            :items="paymentTerms"
          ></v-select>
          <v-select
            v-model="paymentItem.paymentParty"
            outlined
            dense
            label="Payer*"
            :items="availableParties"
          ></v-select>
          <v-autocomplete
            hide-no-data
            dense
            class="mt-2"
            outlined
            clearable
            :loading="paymentTownLoading"
            :search-input.sync="searchTowns"
            v-model="paymentItem.paymentLocation"
            :menu-props="{ closeOnContentClick: true }"
            label="Payment Location"
            :items="paymentTowns"
            item-text="name"
            item-value="locode"
            :filter="filterObject"
          >
            <template v-slot:selection="data">
              <span style="font-size: 12px"
                >{{ data.item.name }} ({{ data.item.locode }})</span
              >
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.locode }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="contractAddModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Manage Contract</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="red"
            @click="deleteContract()"
            v-if="contractItem.id"
            :loading="deletingContract"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-btn
            icon
            color="secondary"
            @click="saveContract()"
            :disabled="!contractItem.contractNo || !contractItem.role"
            :loading="savingContract"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn icon @click="(contractAddModal = false), (contractItem = {})"
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-subtitle v-if="contractItem.bookingContainer">
          <v-chip v-if="contractItem.bookingContainer">
            <v-icon small left>widgets</v-icon
            >{{ contractItem.bookingContainer.ctoNo }}</v-chip
          >
        </v-card-subtitle>
        <v-card-text>
          <v-select
            outlined
            label="Contract Type"
            :items="contractTypes"
            dense
            v-model="contractItem.typeCode"
            item-text="name"
            item-value="value"
          ></v-select>
          <v-text-field
            label="Contract No.*"
            outlined
            dense
            clearable
            v-model="contractItem.contractNo"
          ></v-text-field>
          <v-select
            outlined
            label="Contract Owner*"
            :items="availableOrganisations"
            dense
            v-model="contractItem.contractOwnerId"
            item-text="name"
            item-value="id"
          ></v-select>
          <v-select
            outlined
            label="Owner Role*"
            :items="availableParties"
            dense
            v-model="contractItem.role"
          ></v-select>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Shipment profile config -->
    <v-dialog
      v-model="shipmentProfileConfig"
      width="450px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Profile Configuration </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shipmentProfileConfig = false">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="order.consigneeProfile">
          <v-list dense>
            <v-list-item
              v-for="term in order.consigneeProfile.consigneeProfileIncoTerms"
              :key="term.id"
              @click="setDealTerm(term)"
            >
              <v-list-item-action>
                <v-chip>{{ term.incoTerm }}</v-chip>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-subtitle style="text-transform: capitalize">
                  Freight Charge: {{ term.freightCharge }} -
                  {{ term.paymentTerm }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="text-transform: capitalize">
                  Origin Charge: {{ term.originCharge }} - {{ term.originTerm }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="text-transform: capitalize">
                  Destination Charge: {{ term.destinationCharge }} -
                  {{ term.destinationTerm }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Party search modal -->
    <v-dialog
      v-model="partyModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="loadingRelatedOrganisations" v-if="partyModal">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage {{ partyType }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(partyModal = false), (searchOrganisation = null)"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            placeholder="Search"
            prepend-inner-icon="search"
            v-model="searchOrganisation"
            clearable
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="organisation in filterCustomerOrganisations"
              :key="organisation.id"
              @click="setParty(organisation.relatedOrganisation)"
            >
              <v-list-item-action>
                <v-avatar
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar v-else color="secondary">
                  <h3>{{ organisation.relatedOrganisation.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="organisation.relatedOrganisation.alias"
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Customer Search Modal -->
    <v-dialog
      v-model="customerSearchModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="loadingRelatedOrganisations" v-if="customerSearchModal">
        <v-card-title>
          Search Customer
          <v-spacer></v-spacer>
          <v-btn text @click="customerSearchModal = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            autofocus
            prepend-inner-icon="search"
            v-model="searchCustomer"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto" dense>
            <v-list-item
              v-for="organisation in filterOrganisations"
              :key="organisation.id"
              style="height: 50px"
              @click="setCustomer(organisation)"
            >
              <v-list-item-action>
                <v-avatar
                  size="40"
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar size="40" v-else color="secondary">
                  <h3>{{ organisation.relatedOrganisation.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="organisation.relatedOrganisation.alias"
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterOrganisations.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No organisations found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Select shipment profile -->
    <v-dialog
      v-model="shipmentProfileDialog"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card v-if="shipmentProfileDialog">
        <v-card-title>
          Search Shipment Profiles
          <v-spacer></v-spacer>
          <v-btn text @click="shipmentProfileDialog = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="end">
            <v-col cols="12" sm="4">
              <v-text-field
                placeholder="Search"
                autofocus
                prepend-inner-icon="search"
                v-model="searchProfiles"
                outlined
                dense
                clearable
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            id="profile-info"
            style="cursor: pointer; font-size: 12px"
            :items="filteredProfiles"
            dense
            :loading="loadingShipmentProfiles"
            :headers="shipmentProfileHeaders"
            fixed-header
            :search="searchProfiles"
            height="70vh"
            @click:row="setShipmentProfile"
          >
            <template v-slot:[`item.systemReference`]="{ item }">
              <b>{{ item.systemReference }}</b>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                v-if="item.status == 'Draft'"
                color="blue"
                class="white--text"
                small
                >Draft</v-chip
              >
              <v-chip
                v-else-if="item.status == 'Approved'"
                color="success"
                class="white--text"
                small
                >Approved</v-chip
              >
            </template>
            <template v-slot:[`item.shipper.name`]="{ item }">
              <span v-if="item.shipper">{{ item.shipper.name }}</span>
              <span>
                <span v-if="item.onBehalfShipper">
                  <br />
                  On behalf of {{ item.onBehalfShipper.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.forwarder.name`]="{ item }">
              <span v-if="item.forwarder">{{ item.forwarder.name }}</span>
              <span>
                <span v-if="item.onBehalfForwarder">
                  <br />
                  On behalf of {{ item.onBehalfForwarder.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.consignee.name`]="{ item }">
              <span v-if="item.consignee">{{ item.consignee.name }}</span>
              <span>
                <span v-if="item.onBehalfConsignee">
                  <br />
                  On behalf of {{ item.onBehalfConsignee.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.buyer.name`]="{ item }">
              <span v-if="item.buyer">{{ item.buyer.name }}</span>
              <span>
                <span v-if="item.onBehalfBuyer">
                  <br />
                  On behalf of {{ item.onBehalfBuyer.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.loadingPorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allOriginPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'loading'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.dischargePorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allDestinationPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'discharge'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.originCountry.name`]="{ item }">
              <v-tooltip top v-if="item.originCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="item.originCountry && item.originCountry.iso2"
                      :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.originCountry.name
                }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.documentationPOD`]="{ item }">
              <template v-if="item.consigneeProfilePorts.length > 0">
                {{ item.consigneeProfilePorts[0].name }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </template>

            <template v-slot:[`item.destinationCountry.name`]="{ item }">
              <v-tooltip top v-if="order.destinationCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="
                        item.destinationCountry && item.destinationCountry.iso2
                      "
                      :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.destinationCountry.name
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.incoTerm`]="{ item }">
              <v-chip
                small
                outlined
                class="mt-1"
                v-for="term in item.consigneeProfileIncoTerms"
                :key="term.id"
                >{{ term.incoTerm }}</v-chip
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="referenceModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="referenceType">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage {{ referenceType.label }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="referenceModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            autofocus
            v-model="order[referenceType.field]"
            outlined
            dense
            clearable
            @change="$emit('orderChange')"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="regimeCodeModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card :loading="regimeCodeLoading">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Regime Code </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="regimeCodeModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            autofocus
            prpend-inner-icon="search"
            v-model="regimeCodeSearch"
            outlined
            dense
            clearable
          ></v-text-field>
          <v-virtual-scroll
            v-if="filterRegimeCodes.length > 0"
            class="my-0 py-0"
            :bench="0"
            :items="filterRegimeCodes"
            height="500"
            item-height="85"
          >
            <template v-slot:default="{ item }">
              <v-list-item
                @click="setRegimeCode(item)"
                style="border-bottom: 0.5px solid grey"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.code }}
                    <v-chip class="ml-2" outlined small v-if="item.steri">
                      <v-icon left color="blue" small>ac_unit</v-icon>Steri Code
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon class="mr-2" color="grey" small>thermostat</v-icon
                    >Set Point: {{ item.setPointTemp }}°C
                    <v-icon class="mx-2" color="red" small>thermostat</v-icon
                    >Max: {{ item.maxProductTemp }}°C
                    <v-icon class="mx-2" color="blue" small>thermostat</v-icon
                    >Min {{ item.minProductTemp }}°C
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.recipeDescription }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterRegimeCodes.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No regime codes found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
// import FormModal from "./Dialogs/FormModal.vue";
export default {
  props: [
    "order",
    "containers",
    "loadingRelatedOrganisations",
    "related",
  ],
  components: {
    // FormModal
  },
  data: () => ({
    contractItem: {},
    contractAddModal: false,
    contractKey: 150,
    contractTypes: [
      {
        name: "Coldstore",
        value: "COLDSTORE",
      },
      {
        name: "Shipping Line",
        value: "SHIPPING_LINE",
      },
      {
        name: "Weigh Bridge",
        value: "WEIGH_BRIDGE",
      },
    ],
    customerSearchModal: false,
    referenceType: {},
    referenceModal: false,
    form: null,
    fullscreen: false,
    generatingQ67: false,
    imageLoading: false,
    incoTermModal: false,
    incoTerms: [],
    item: {},
    integrationForms: [],
    loadingForms: false,
    loadingShipmentProfiles: false,
    partyModal: false,
    partyType: null,
    paymentModal: false,
    paymentItem: {},
    paymentKey: 8000,
    paymentTypes: [
      { key: "Additional", value: "Additional Charges" },
      { key: "OceanFreight", value: "Basic Freight" },
      { key: "DestinationHaulage", value: "Destination Haulage Charges" },
      { key: "DestinationTerminalHandling", value: "Destination Port Charges" },
      { key: "OriginTerminalHandling", value: "Origin Haulage Charges" },
      { key: "OriginHaulage", value: "Origin Port Charges" },
    ],
    paymentTerms: [
      { key: "Prepaid", value: "Pre-paid" },
      { key: "Collect", value: "Collect" },
      { key: "PayableElsewhere", value: "Payable Elsewhere" },
    ],
    paymentTowns: [],
    paymentTownTimeout: undefined,
    paymentTownLoading: false,
    previewModal: false,
    profileKey: 100,
    regimeCodeModal: false,
    regimeCodeLoading: false,
    regimeCodes: [],
    regimeCodeSearch: null,
    savingContract: false,
    searchCustomer: null,
    searchOrganisation: null,
    searchProfiles: null,
    searchTowns: null,
    shipmentProfileHeaders: [
      {
        text: "System Ref",
        value: "systemReference",
        align: "left",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },

      {
        text: "Reference",
        value: "reference",
        align: "center",
      },
      {
        text: "Consignee",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },
      {
        text: "Forwarder",
        value: "forwarder.name",
        align: "center",
      },
      {
        text: "Origin",
        value: "originCountry.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Loading Ports",
        value: "loadingPorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Destination",
        value: "destinationCountry.name",
        sortable: false,
        align: "center",
      },

      {
        text: "Discharge Ports",
        value: "dischargePorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationCity",
        sortable: false,
        align: "center",
        documentName: null,
      },
      {
        text: "Inco Term(s)",
        value: "incoTerm",
        sortable: false,
        align: "center",
      },
    ],
    savingPaymentItem: false,
    shipmentProfileConfig: false,
    shipmentProfileDialog: false,
    shipmentProfiles: {
      data: [],
    },
    viewFormModal: false,
  }),
  watch: {
    "order.orderCustomerId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getShipmentProfiles();
        }
      },
    },
    searchTowns(val) {
      if (this.paymentTownLoading) {
        clearTimeout(this.paymentTownTimeout);
      }
      if (val && val.length > 2) {
        this.paymentTownLoading = true;
        this.paymentTownTimeout = setTimeout(async () => {
          this.paymentTowns = await this.$API.searchTowns({
            params: {
              search: val,
            },
          });
          this.paymentTownLoading = false;
        }, 250);
      } else {
        clearTimeout(this.paymentTownTimeout);
        this.paymentTownLoading = false;
      }
    },
  },
  async created() {
    this.incoTerms = await this.$API.getIncoTerms();
  },
  computed: {
    availableOrganisations() {
      let keys = [
        "customer",
        "shipper",
        "forwarder",
        "consignee",
        "firstNotify",
        "secondNotify",
        "shippingLine",
      ];
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (this.order[keys[i]]) {
          result.push({
            name: this.order[keys[i]].name,
            id: this.order[keys[i] + "Id"],
          });
        }
      }
      return result;
    },
    availableParties() {
      let keys = [
        "Shipper",
        "Forwarder",
        "Consignee",
        "Notify Party",
        "Shipping Line",
        "Other",
      ];
      return keys;
    },
    filterCustomerOrganisations() {
      let result = [...this.related.data] ?? [];
      if (this.order.orderCustomer) {
        let find = result.find(
          (x) => x.relatedOrganisationId == this.order.orderCustomerId
        );
        if (!find) {
          result.push({
            relatedOrganisation: this.order.orderCustomer,
            relatedOrganisationId: this.order.orderCustomerId,
          });
        }
      }
      if (this.searchOrganisation) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchOrganisation.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchOrganisation.toLowerCase()))
        );
      }
      result.sort((a, b) =>
        a.relatedOrganisation.name > b.relatedOrganisation.name
          ? 1
          : b.relatedOrganisation.name > a.relatedOrganisation.name
          ? -1
          : 0
      );
      return result;
    },
    filterOrganisations() {
      let result = [...this.related.data] ?? [];
      // console.log(result);
      result = result.filter((x) => x.isActive && x.isCustomer);
      // let unique = [...new Set(result.map((x) => x.relatedOrganisationId))];
      // unique = unique.map((x) =>
      //   result.find((y) => y.relatedOrganisationId == x)
      // );
      // let finalResult = [];
      // for (let i = 0; i < unique.length; i++) {
      //   let find = result.find(
      //     (x) => x.relatedOrganisationId == unique[i].relatedOrganisationId
      //   );
      //   if (find) {
      //     finalResult.push(find);
      //   }
      // }
      if (this.searchCustomer) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchCustomer.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchCustomer.toLowerCase()))
        );
      }

      return result;
    },
    filteredProfiles() {
      let result = this.shipmentProfiles.data.filter(
        (x) => x.status == "Approved"
      );
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.systemReference &&
              x.systemReference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.reference &&
              x.reference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.buyer &&
              x.buyer.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.forwarder &&
              x.forwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfForwarder &&
              x.onBehalfForwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfShipper &&
              x.onBehalfShipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfConsignee &&
              x.onBehalfConsignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consigneeProfileIncoTerms &&
              x.consigneeProfileIncoTerms
                .map((y) => y.incoTerm.toLowerCase())
                .includes(this.searchProfiles.toLowerCase()))
        );
      }
      return result;
    },
    filterRegimeCodes() {
      let result = this.regimeCodes;
      if (this.regimeCodeSearch) {
        result = result.filter((x) =>
          x.code.toLowerCase().includes(this.regimeCodeSearch.toLowerCase())
        );
      }
      return result;
    },
  },
  methods: {
    addContractItem(
      item = {
        typeCode: "SHIPPING_LINE",
        type: "Shipping Line",
        bookingId: this.order.id,
      }
    ) {
      this.contractItem = item;
      this.contractAddModal = true;
    },
    addPayment() {
      if (!this.order.bookingPaymentDetails) {
        this.order.bookingPaymentDetails = [];
      }
      this.paymentItem = {
        index: this.order.bookingPaymentDetails.length,
      };
      this.paymentModal = true;
    },
    async deleteContract() {
      this.deletingContract = true;
      await this.$API.updateContract({
        id: this.contractItem.id,
        isActive: false,
        isDeleted: true,
      });
      this.order.linkedContracts = this.order.linkedContracts.filter(
        (x) => x.id != this.contractItem.id
      );
      this.contractAddModal = false;
      this.deletingContract = false;
    },
    deletePaymentItem(item) {
      this.order.bookingPaymentDetails.splice(item.index, 1);
    },

    editPaymentItem(item) {
      this.paymentItem = item;
      this.paymentModal = true;
    },
    editReference(type, label) {
      this.referenceType = { field: type, label: label };
      this.referenceModal = true;
    },
    findChargeType(type) {
      return this.paymentTypes.find((x) => x.key == type).value;
    },
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.locode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    formatParty(party) {
      switch (party) {
        case "forwarder":
          return "Forwarder";
        case "buyer":
          return "Buyer";
        case "consignee":
          return "Consignee";
        case "firstNotify":
          return "Notify Party";
        case "secondNotify":
          return "Notify Party";
        default:
          return party;
      }
    },
    async getRegimeCodes(modal = false) {
      this.regimeCodeModal = modal;
      this.regimeCodeLoading = true;
      let params = {};
      if (this.order.destinationCountry) {
        params.countryCode = this.order.destinationCountry.iso2;
      }
      this.regimeCodes = await this.$API.getRegimeCodes({
        params: params,
      });
      this.regimeCodeLoading = false;
    },
    async generateQ67Excel() {
      this.generatingQ67 = true;
      let result = await this.$API.generateQ67Excel({
        bookingId: this.order.id,
        comment: this.q67Comment,
      });
      if (result) {
        this.order.q67 = result;
        this.order.q67Id = result.id;
        this.q67CommentModal = false;
        this.q67Comment = null;
        this.$message({
          type: "success",
          message: `Q67 Generated Successfully`,
        });
      }
      this.generatingQ67 = false;
    },

    async getShipmentProfiles() {
      this.loadingShipmentProfiles = true;
      this.shipmentProfiles = await this.$API.listCustomerConsigneeProfile(
        this.order.orderCustomerId,
        {}
      );
      this.loadingShipmentProfiles = false;
    },
    productSummary() {
      let products = [].concat.apply(
        [],
        this.containers.map((x) => x.temporaryProducts)
      );
      products.forEach((x) => {
        x.hash = x.product + x.variation;
      });
      let unique = [...new Set(products.map((x) => x.hash))];
      let result = [];
      for (let i = 0; i < unique.length; i++) {
        let filtered = products.filter((x) => x.hash == unique[i]);
        let detail = filtered[0];
        let obj = {
          count: filtered.length,
          product: detail.product,
          variation: detail.variation,
          monitoringService: detail.monitoringService,
        };
        result.push(obj);
      }
      result.sort((a, b) =>
        a.product > b.product ? 1 : b.product > a.product ? -1 : 0
      );
      return result;
    },
    serviceSummary() {
      let result = [];
      for (let i = 0; i < this.containers.length; i++) {
        let uniqueServices = [
          ...new Set(
            this.containers[i].temporaryProducts
              .filter((x) => x.monitoringService)
              .map((x) => x.monitoringService)
          ),
        ];
        for (let j = 0; j < uniqueServices.length; j++) {
          let find = result.find((x) => x.service == uniqueServices[j]);
          if (find) {
            find.count++;
          } else {
            result.push({
              count: 1,
              service: uniqueServices[j],
            });
          }
        }
      }
      // let products = [].concat.apply([], this.containers.map(x=>x.temporaryProducts))
      // products.forEach(x=>{
      //   x.hash = x.monitoringService
      // })
      // let unique = [...new Set(products.filter(x=>x.hash).map(x=>x.hash))]
      // let result = []
      // for(let i=0;i<unique.length;i++){
      //   let filtered = products.filter(x=>x.hash == unique[i])
      //   let detail = filtered[0]
      //   let obj = {
      //     count: filtered.length,
      //     service: detail.monitoringService
      //   }
      //   result.push(obj)
      // }
      return result;
    },
    removeCustomer() {
      this.booking.orderCustomer = undefined;
      this.booking.orderCustomerId = undefined;
      this.booking.consigneeProfile = undefined;
      this.booking.consigneeProfileId = undefined;
    },
    removeProfile() {
      this.order.consigneeProfile = null;
      this.order.consigneeProfileId = null;
      this.order.dealTerm = null;
      this.order.dealTermId = null;
      this.profileKey++;
      this.$emit("orderChange");
    },
    async saveContract() {
      this.savingContract = true;
      if (this.contractItem.typeCode) {
        let find = this.contractTypes.find(
          (x) => x.value == this.contractItem.typeCode
        );
        this.contractItem.type = find.name;
        if (
          this.contractItem.typeCode == "SHIPPING_LINE" &&
          !this.contractItem.bookingContainerId
        ) {
          this.order.contractNumber = this.contractItem.contractNo;
        }
      }
      if (this.contractItem.id) {
        await this.$API.updateContract(this.contractItem);
      } else {
        let result = await this.$API.createContract(this.contractItem);
        // if(this.contractItem.contractOwnerId){
        //     this.contractItem.contractOwner = this.availableOrganisations.find(x => x.id == this.contractItem.contractOwnerId)
        // }
        this.order.linkedContracts.push(result);
      }
      this.contractAddModal = false;
      this.savingContract = false;
      this.$emit("orderChange");
    },
    async savePaymentItem() {
      this.savingPaymentItem = true;
      this.paymentItem.bookingId = this.order.id;
      // let find = this.order.bookingPaymentDetails.findIndex(
      //   (x) => x.index === this.paymentItem.index
      // );
      if (this.paymentItem.id) {
        await this.$API.updateBookingPayment(this.paymentItem);
        this.order.bookingPaymentDetails[find] = this.paymentItem;
      } else {
        let result = await this.$API.createBookingPayment(this.paymentItem);
        this.order.bookingPaymentDetails.push(result);
      }
      this.paymentKey++;
      this.paymentModal = false;
      this.paymentItem = {};
      this.savingPaymentItem = false;
    },
    async setCustomer(organisation) {
      this.order.orderCustomer = organisation.relatedOrganisation;
      this.order.orderCustomerId = organisation.relatedOrganisationId;
      let parties = [
        "buyer",
        "shipper",
        "consignee",
        "forwarder",
        "firstNotify",
        "secondNotify",
        "courierParty",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.order[parties[i]] = null;
        this.order[parties[i] + "Id"] = null;
      }
      for (let i = 0; i < parties.length; i++) {
        if (organisation[parties[i]]) {
          this.order[parties[i]] = organisation.relatedOrganisation;
          this.order[parties[i] + "Id"] = organisation.relatedOrganisationId;
        }
      }

      this.customerSearchModal = false;
      this.$emit("orderChange");
    },
    async setDealTerm(term) {
      this.order.dealTermId = term.id;
      this.order.dealTerm = term;
      this.order.incoTerm = term.incoTerm;
      this.order.freightTerm = term.paymentTerm;
      this.shipmentProfileConfig = false;
      this.order.bookingPaymentDetails = [];
      if (this.order.dealTerm) {
        if (this.order.dealTerm.freightCharge) {
          this.paymentItem = {
            chargeType: "OceanFreight",
            index: this.order.bookingPaymentDetails.length,
            paymentTerm: this.order.dealTerm.paymentTerm,
            paymentParty: this.formatParty(this.order.dealTerm.freightCharge),
          };
          await this.savePaymentItem();
        }

        if (this.order.dealTerm.originCharge) {
          this.paymentItem = {
            chargeType: "OriginTerminalHandling",
            index: this.order.bookingPaymentDetails.length,
            paymentTerm: this.order.dealTerm.originTerm,
            paymentParty: this.formatParty(this.order.dealTerm.originCharge),
          };
          await this.savePaymentItem();
        }

        if (this.order.dealTerm.destinationCharge) {
          this.paymentItem = {
            chargeType: "DestinationTerminalHandling",
            index: this.order.bookingPaymentDetails.length,
            paymentTerm: this.order.dealTerm.destinationTerm,
            paymentParty: this.formatParty(
              this.order.dealTerm.destinationCharge
            ),
          };
          await this.savePaymentItem();
        }
        // if (term.consigneeProfileShippingContracts) {
        //     this.selectedCarriers = term.consigneeProfileShippingContracts.filter(x => x.shippingLine).map(x => x.shippingLine.scacCode).filter(Boolean)
        //     this.searchKey++
        // }
        // incoTerm.consigneeProfileShippingContracts
      }
      this.$emit("orderChange");
    },
    setIncoTerm(term) {
      this.order.incoTerm = term.abbreviation;
      this.incoTermModal = false;
      this.$emit("orderChange");
    },
    setParty(organisation) {
      switch (this.partyType) {
        case "Shipper":
          this.order.shipper = organisation;
          this.order.shipperId = organisation.id;
          break;
        case "Consignee":
          this.order.consignee = organisation;
          this.order.consigneeId = organisation.id;
          break;
        case "Forwarder":
          this.order.forwarder = organisation;
          this.order.forwarderId = organisation.id;
          break;
        case "Buyer":
          this.order.buyer = organisation;
          this.order.buyerId = organisation.id;
          break;
        case "First Notify":
          this.order.firstNotify = organisation;
          this.order.firstNotifyId = organisation.id;
          break;
        case "Second Notify":
          this.order.secondNotify = organisation;
          this.order.secondNotifyId = organisation.id;
          break;
        case "Courier Party":
          this.order.courierParty = organisation;
          this.order.courierPartyId = organisation.id;
          break;
      }

      this.partyModal = false;
      this.$emit("orderChange");
    },
    async setShipmentProfile(profile) {
      let profileDetail = await this.$API.getConsigneeProfile(profile.id);
      this.order.consigneeProfile = profile;
      this.order.consigneeProfileId = profile.id;
      let parties = [
        "shipper",
        "consignee",
        "forwarder",
        "buyer",
        "firstNotify",
        "secondNotify",
        "courierParty",
        "onBehalfShipper",
        "onBehalfConsignee",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.order[parties[i]] = profile[parties[i]];
        this.order[parties[i] + "Id"] = profile[parties[i] + "Id"];
      }
      this.order.originCountry = profile.originCountry;
      this.order.originCountryId = profile.originCountryId;
      this.order.destinationCountry = profile.destinationCountry;
      this.order.destinationCountryId = profile.destinationCountryId;
      if (profileDetail.finalDestinationValue) {
        this.order.finalDestinationValue = profileDetail.finalDestinationValue;
        this.order.finalDestinationCity = profileDetail.finalDestinationCity;
      }

      this.order.regimeCode = profileDetail.regimeCode ? profileDetail.regimeCode : this.order.regimeCode;
      this.order.regime = profileDetail.regime ? profileDetail.regime : this.order.regime;
      this.order.regimeId = profileDetail.regimeId ? profileDetail.regimeId : this.order.regimeId;
      this.order.caProduct = profileDetail.caProduct;
      this.order.isPrivateHaulage = profileDetail.isPrivateHaulage;
      this.order.isRailage = profileDetail.isRailage;
      this.order.isCarrierHaulage = profileDetail.isCarrierHaulage;
      if (profileDetail.consigneeProfileIncoTerms.length == 1) {
        this.setDealTerm(profileDetail.consigneeProfileIncoTerms[0]);
      }
      if (!profile.consigneeProfileProducts) {
        profile.consigneeProfileProducts =
          await this.$API.getConsigneeProfileProducts(profile.id);
      }
      if (!profile.allDestinationPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "discharge"
        );
        if (findDest.length == 1) {
          this.order.portOfDischargeValue = findDest[0].code;
          this.order.finalDestinationValue = findDest[0].code;
          this.order.portOfDischargeCity = findDest[0].name;
          this.order.finalDestinationCity = findDest[0].name;
        }
      }

      if (!profile.allOriginPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "origin"
        );
        if (findDest.length == 1) {
          this.order.portOfLoadValue = findDest[0].code;
          this.order.portOfLoadCity = findDest[0].name;
        }
      }
      this.shipmentProfileDialog = false;
      this.contractKey++;
      this.allOriginPorts = await this.$API.getPortsByCountry(
        profile.originCountry.iso2
      );
      this.allDestinationPorts = await this.$API.getPortsByCountry(
        profile.destinationCountry.iso2
      );
      this.$emit("orderChange");
    },
    async setRegimeCode(regime) {
      this.order.regimeCode = regime.code;
      this.order.regime = regime;
      this.order.regimeId = regime.id;
      this.regimeCodeModal = false;
      this.$emit("orderChange");
    },
  },
};
</script>
<template>
  <div>
    <v-card
      outlined
      :loading="loading"
      style="
        border-radius: 20px;
        background-color: var(--v-toolbar-lighten1) !important;
      "
    >
      <v-card-text class="my-0 py-0">
        <v-list subheader dense>
          <v-subheader style="font-size: 16px">
                <v-icon color="grey" class="mr-2">handshake</v-icon>
                Deal Terms
              </v-subheader>
              <v-divider></v-divider>
              <v-row class="my-2">
                <v-col cols="12" sm="6" class="ma-0 pa-0">
                  <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-action>
                      <v-icon small color="grey"> tag </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="orderFinancialSetting.incoTerm">
                        {{ orderFinancialSetting.incoTerm }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle> INCO Term </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="incoterm in incoTerms"
                    :key="incoterm.id"
                    @click="
                      orderFinancialSetting.incoTerm = incoterm.abbreviation;
                      saveOrderFinancialSetting();
                    "
                  >
                    <v-list-item-title>{{
                      incoterm.abbreviation
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" class="ma-0 pa-0">

              <v-list-item @click="currencyModal = true">
                <v-list-item-action>
                  <v-icon small color="grey"> currency_exchange </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="orderFinancialSetting.baseCurrency">
                    {{ orderFinancialSetting.baseCurrency }}
                    <span class="ml-2">
                      {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle> Base Currency </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
                  </v-col>
                  <v-col cols="12" class="ma-0 pa-0" >
                    <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-action>
                      <v-icon small color="grey"> trending_up </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="orderFinancialSetting.type == 'FIXED'"
                        >Fixed Pricing</v-list-item-title
                      >
                      <v-list-item-title
                        v-else-if="orderFinancialSetting.type == 'MGP'"
                        >Consignment: Minimum Guaranteed
                        Price</v-list-item-title
                      >
                      <v-list-item-title
                        v-else-if="
                          orderFinancialSetting.type == 'FULL_CONSIGNMENT'
                        "
                        >Full Consignment</v-list-item-title
                      >
                      <v-list-item-subtitle>Price Terms</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small>expand_more</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <v-list dense>
                  <v-list-item
                    @click="
                      orderFinancialSetting.type = 'FIXED';
                      saveOrderFinancialSetting();
                    "
                  >
                    <v-list-item-title>Fixed Pricing</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="
                      orderFinancialSetting.type = 'MGP';
                      saveOrderFinancialSetting();
                    "
                  >
                    <v-list-item-title
                      >Consignment: Minimum Guaranteed Price</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    @click="
                      orderFinancialSetting.type = 'FULL_CONSIGNMENT';
                      saveOrderFinancialSetting();
                    "
                  >
                    <v-list-item-title>Full Consignment</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
                  </v-col>
                </v-row>
              

          
              <v-divider></v-divider>

          <v-subheader style="font-size: 16px">
            <v-icon color="grey" class="mr-2">shopping_cart</v-icon>
            Order Items
            <v-chip
              small
              class="ml-1"
              :color="
                stock.filter((x) => x.assignedProgress >= 100).length /
                  stock.length >=
                1
                  ? 'success'
                  : stock.filter((x) => x.assignedProgress >= 100).length > 0
                  ? 'blue'
                  : 'orange'
              "
            >
              {{ stock.filter((x) => x.assignedProgress >= 100).length }}
              /
              {{ stock.length }}
            </v-chip>
            <v-spacer></v-spacer>

            <v-btn small color="blue" rounded outlined @click="assignStock()">
              <v-icon left small>assignment_turned_in</v-icon>
              Assign Stock
            </v-btn>
            <v-btn icon color="primary" @click="addOrderItem()">
              <v-icon>add_circle_outline</v-icon>
            </v-btn>
          </v-subheader>
          <v-divider></v-divider>
          <v-list-item v-if="stock.length == 0">
            <v-list-item-content class="text-center">
              <span style="font-size: 12px; color: grey">No Order Items</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="(container, index) in stock" :key="container.id">
            <v-list-item-action class="mx-0 px-0">
              <v-progress-circular
                :value="container.assignedProgress"
                size="20"
                :color="
                  container.assignedProgress < 20
                    ? 'red'
                    : container.assignedProgress < 50
                    ? 'orange'
                    : container.assignedProgress < 100
                    ? 'blue'
                    : 'success'
                "
              ></v-progress-circular>
            </v-list-item-action>
            <v-list-item-content
              class="mr-0 pr-0 ml-1"
              style="max-width: 125px"
            >
              <v-list-item-title>
                {{ container.ctoNo }}
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="container.containerNo"
                style="font-size: 12px"
              >
                {{ container.containerNo }}
              </v-list-item-subtitle>
              <v-list-item-subtitle style="font-size: 11px">
                {{ container.assignedStock }} Assigned Pallets
              </v-list-item-subtitle>
              <v-list-item-subtitle
                style="font-size: 11px"
                v-if="getContainerDescription(container.containerTypeCode)"
              >
                {{ getContainerDescription(container.containerTypeCode) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content class="ml-0 pl-0">
              <v-menu offset-y rounded>
                <template v-slot:activator="{ on }">
                  <v-chip
                    class="ml-1"
                    style="max-width: 100px"
                    v-on="on"
                    x-small
                    :color="orderItemStatusColor(container.stockStatus)"
                  >
                    <v-icon x-small left color="white">{{
                      orderItemStatusIcon(container.stockStatus)
                    }}</v-icon>
                    <span style="text-transform: none; color: white">
                      {{ container.stockStatus ?? 'None' }}
                    </span>
                  </v-chip>
                </template>
                <v-list dense subheader>
                  <v-list-item
                    @click="
                      (container.stockStatus = status.name), saveItem(container)
                    "
                    v-for="(status, index) in orderItemStatus"
                    :key="index"
                  >
                    <v-list-item-action>
                      <v-icon :color="status.color">{{ status.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ status.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <ul class="ml-0 pl-0">
                <li
                  class="ml-0 pl-0"
                  style="font-size: 10px"
                  v-for="item in container.orderCargoItems"
                  :key="item.id"
                >
                  <span v-if="item.product">
                    {{ $Format.capitalizeFirstLetter(item.product.name) }}
                    <span v-if="item.varietyProduct"
                      >:
                      {{
                        $Format.capitalizeFirstLetter(item.varietyProduct.name)
                      }}</span
                    >
                    <br />
                    <span style="color: grey">{{ productSpec(item) }}</span>
                  </span>
                </li>
              </ul>
            </v-list-item-content>
            <v-list-item-action>
              <v-container>
                <v-row align="center" justify="center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        small
                        @click="addOrderItem(container)"
                      >
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        small
                        color="teal"
                        v-on="on"
                        @click="cloneItem(container)"
                      >
                        <v-icon small>content_copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Create Copy</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        small
                        color="red"
                        v-on="on"
                        @click="removeOrderItem(container, index)"
                      >
                        <v-icon small>delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>

                  <!-- <v-tooltip top v-if=!container.packingListDocument>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        small
                        color="teal"
                        v-on="on"
                      >
                        <v-icon small>post_add</v-icon>
                      </v-btn>
                    </template>
                    <span>Generate Packing List</span>
                  </v-tooltip> -->
                </v-row>
              </v-container>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <v-dialog v-model="assignStockModal" style="height: 98dvh" fullscreen>
      <AssignStock
        :orders="[order]"
        @close="(assignStockModal = false), $emit('refreshStock')"
        :refreshKey="refreshKey"
      />
    </v-dialog>

    <v-dialog
      v-model="orderItemModal"
      persistent
      width="95vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Manage Order Item </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
            icon
            color="secondary"
            :loading="savingOrderItem"
            @click="saveOrderItem()"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn v-if="orderItem.id" icon color="red" @click="removeOrderItem()"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-btn text @click="(orderItemModal = false), (orderItem = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
         
          <v-list dense>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-row align="center">
                      <v-col cols="12" sm="5" class="mr-0 pr-1 mb-0 pb-1">
                        <v-autocomplete
                          class="my-0 py-0"
                          hide-details
                          outlined
                          dense
                          clearable
                          label="Container Type"
                          v-model="orderItem.containerTypeCode"
                          :items="containerTypes"
                          item-text="shortDescription"
                          item-value="typeCode"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col v-if="!orderItem.id" cols="12" sm="4" class="mx-0 pl-1 pr-0 mb-0 pb-1" justify="center">
                        <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <el-input-number
                            v-on="on"
                            class="my-0 py-0"
                            :min="1"
                            :value="orderItem.quantity"
                            size="large"
                            v-model="orderItem.quantity"
                          ></el-input-number>
                        </template>
                        <span>Number of containers</span>
                      </v-tooltip>
                      </v-col>
                      
                      <v-col cols="12" sm="3" class="pl-0 mb-0 pb-1">
                        <v-autocomplete
                          v-if="!orderItem.id"
                          label="Base Unit"
                          class="my-0 py-0"
                          v-model="orderItem.baseUnit"
                          :items="productQuantityTypes"
                          item-text="text"
                          item-value="value"
                          outlined
                          hide-details
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row  dense v-if="orderItem.id">
                      <v-col cols="12" sm="12" md="9" class="mr-0 pr-0 my-0 py-0">
                        <v-list-item v-if="orderItem.id">
                          <v-list-item-content>
                            <v-list-item-title>No. of Pallets</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <el-input-number
                                  v-on="on"
                                  class="my-0 py-0 mr-0 pr-0"
                                  :min="1"
                                  style="width: 180px"
                                  :value="orderItem.totalPallets"
                                  size="small"
                                  v-model="orderItem.totalPallets"
                                ></el-input-number>
                              </template>
                              <span>No. of Pallets</span>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                      </v-col>
                      
                    </v-row>

                    <!-- <v-list-item v-if="!orderItem.id">
                      <v-list-item-content>
                        <v-list-item-title>Quantity</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <el-input-number
                            v-on="on"
                            class="my-0 py-0"
                            :min="1"
                            :value="orderItem.quantity"
                            size="mini"
                            v-model="orderItem.quantity"
                          ></el-input-number>
                        </template>
                        <span>Number of containers</span>
                      </v-tooltip>
                      </v-list-item-action>
                    </v-list-item> -->
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="2"
                    class="ma-0 pa-0"
                    v-if="!orderItem.id"
                  >
                    <v-list-item>
                      <v-list-item-action class="mx-0 px-0 mr-1">
                        <v-icon color="grey" small>widgets</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Containerised</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch
                          v-model="orderItem.containerised"
                          hide-details
                          color="blue"
                        ></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action class="mx-0 px-0 mr-1">
                        <v-icon color="grey" small>widgets</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Palletised</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch
                          v-model="orderItem.palletised"
                          hide-details
                          color="blue"
                        ></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="3"
                    class="ma-0 pa-0"
                    v-if="!orderItem.id"
                  >
                    <v-list-item
                      v-if="orderItem.containerised && orderItem.palletised"
                    >
                      <v-list-item-action class="mx-0 px-0 mr-1">
                        <v-icon color="grey" small>tag</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >Pallets per Container</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <el-input-number
                            v-on="on"
                              :min="0"
                              :value="orderItem.palletPerContainer"
                              size="mini"
                              v-model="orderItem.palletPerContainer"
                            ></el-input-number>
                          </template>
                          <span>Pallets per Container</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item
                      v-if="!orderItem.containerised && orderItem.palletised"
                    >
                      <v-list-item-action class="mx-0 px-0 mr-1">
                        <v-icon color="grey" small>tag</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ formatBaseUnit(orderItem.baseUnit) }}(s) per
                          Pallet</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <el-input-number
                            v-on="on"
                              :min="0"
                              :value="orderItem.unitPerPallet"
                              size="mini"
                              v-model="orderItem.unitPerPallet"
                            ></el-input-number>
                          </template>
                          <span>{{ formatBaseUnit(orderItem.baseUnit) }}(s) per
                        Pallet</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item v-if="orderItem.containerised">
                      <v-list-item-action class="mx-0 px-0 mr-1">
                        <v-icon color="grey" small>tag</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ formatBaseUnit(orderItem.baseUnit) }}(s) per
                          Container</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <el-input-number
                              v-on="on"
                              :min="0"
                              :value="orderItem.unitPerContainer"
                              size="mini"
                              v-model="orderItem.unitPerContainer"
                            ></el-input-number>
                          </template>
                          <span>{{ formatBaseUnit(orderItem.baseUnit) }}(s) per
                          Container</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="3">
                    <v-row justify="center">
                      <v-col cols="12" sm="12" md="8" class="pb-1 pt-0 pr-1 mb-0" align="center" justify="center">
                      <!-- <v-select
                      dense
                      hide-details
                      outlined
                      disabled
                      label="Base Currency"
                      v-model="orderFinancialSetting.baseCurrency">
                      </v-select> -->
                      <v-list-item>
                        <v-list-item-action class="mx-0 px-0 mr-1">
                          <v-icon color="grey" small>currency_exchange</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Factored Rate</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-switch
                            v-model="createFactoredRate"
                            hide-details
                            color="blue"
                          ></v-switch>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                      <v-col cols="12" sm="6" md="4" class="pb-1 pl-0 pt-1">
                        <v-text-field
                        label="Base Currency"
                        outlined
                        dense
                        disabled
                        hide-details
                        v-model="orderFinancialSetting.baseCurrency"
                      ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4" class="pt-0 pr-1 pb-3"
                      v-if="createFactoredRate">
                          <v-autocomplete
                          label="Currency"
                          outlined
                          dense
                          hide-details
                          :items="currencies"
                          v-model="selectedCurrency"
                        ></v-autocomplete>
                        </v-col>
                      <v-col cols="12" sm="6" md="4" class="pt-0 pr-1 pb-3 pl-0"
                      v-if="createFactoredRate">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            label='Fact. Rate'
                            outlined
                            hide-details
                            dense
                            number
                            v-bind="attrs"
                            v-on="on"
                            v-model.number="factoredRate">
                              <template v-slot:prepend-inner>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!validFactoredRate"
                                    @click="applyFactoredRate = !applyFactoredRate">
                                      playlist_add</v-icon>
                                  </template>
                                  <span v-if="!applyFactoredRate">Add to Line Items</span>
                                  <span v-if="applyFactoredRate">Close add to Line Items</span>
                                </v-tooltip>
                              </template>
                              <template v-slot:append>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="applyCurrentRate">
                                      arrow_back</v-icon>
  
                                  </template>
                                  <span>Apply current rate</span>
                                </v-tooltip>
                              </template>
                            </v-text-field>

                          </template>
                          <span>Apply your factored exchange rate here</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="pt-0 pl-0 pb-3"
                      v-if="createFactoredRate">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            :label="`Current rate`"
                            :loading="loadingCurrency"
                            v-model.number="currentExchangeRate"
                            outlined
                            disabled
                            hide-details
                            number
                            dense
                            v-bind="attrs"
                            v-on="on">
                            </v-text-field>
                          
                          </template>
                          <span>Current exchange (+/- 5min delay)</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <div style="width: 100%">
            <OrderItemDetail :orderItem="orderItem" :orderFinancialSetting="orderFinancialSetting" :tradedProducts="tradedProducts" :related="related" :tradedVariations="tradedVariations" :products="products" :orderSettings="orderSettings" :factoredRate="factoredRate" :applyFactoredRate="applyFactoredRate" :factoredRateCurrency="selectedCurrency" @closeApplyingInterface="closeApply" />
        
          </div>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="cargoModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-text>
          <ProductSpecification
            :cargoItem="cargoItem"
            :bookingId="order.id"
            @close="(cargoModal = false), (cargoItem = {})"
            @save="saveCargoItem"
            :autosave="cargoItem.id != undefined"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="errorSnackbar" :timeout="2000" color="red"
      >Quantity of items must equal 100%</v-snackbar
    >

    <v-dialog v-model="salesModal" width="800px">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title> 
            <span v-if="salesItem.id">Edit</span>
            <span v-else>Add</span>
            Sales Item </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="saveSalesItem" :loading="savingSalesItem" icon color="primary" :disabled="!salesItem.productId || !salesItem.varietyId || !salesItem.unitPrice || !salesItem.unitQuantity">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn text @click="salesModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="my-0 py-0"
              >
                <v-select
                  hide-details
                  class="my-1"
                  v-model="salesItem.type"
                  label="Type"
                  dense
                  outlined
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: 'Fixed Price', value: 'FIXED' },
                    { name: 'Minimum Guaranteed Price', value: 'MGP' },
                    { name: 'Market Sales Price', value: 'MARKET_RATE' },
                  ]"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="my-0 py-0"
              ></v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-menu
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" class="text-left">
                          <v-list-item-action>
                            <v-icon>today</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ formatDate(salesItem.date) }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Date
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-date-picker
                        v-model="salesItem.date"
                        no-title
                        scrollable
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="3" class="my-0 py-0">
                   <v-autocomplete outlined disabled dense label="Order Item" v-model="salesItem.bookingContainerId" :items="containers" item-text="ctoNo" item-value="id">

                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          data.item.ctoNo
                        }}</v-list-item-title>
                        <v-list-item-subtitle v-if="data.item.containerNo"
                          >{{ data.item.containerNo }}
                          </v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </template>
                   </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" class="my-0 py-0">
                    <v-text-field
                      label="No. of Units"
                      v-model="salesItem.unitQuantity"
                      type="number"
                      step="1"
                      :min="1"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">

              <v-row>
                  <v-col cols="12" sm="6">
                    <v-row>
                      <v-col cols="12" sm="3" class="mr-0 pr-0">
                        <v-autocomplete
                          label="Currency"
                          outlined
                          dense
                          hide-details
                          :items="currencies"
                          v-model="salesItem.currency"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="9" class="ml-0 pl-0">
                        <v-text-field
                          label="Unit Price*"
                          :prefix="getSymbol(salesItem.currency)"
                          outlined
                          dense
                          hide-details
                          type="number"
                          v-model="salesItem.unitPrice"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-row>
                      <v-col cols="12" sm="3" class="mr-0 pr-0">
                        <v-autocomplete
                          disabled
                          label="Currency"
                          outlined
                          dense
                          hide-details
                          :items="currencies"
                          v-model="salesItem.baseCurrency"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="9" class="ml-0 pl-0">
                        <v-text-field
                          label="Unit Base Price*"
                          :prefix="getSymbol(salesItem.baseCurrency)"
                          outlined
                          dense
                          hide-details
                          type="number"
                          v-model="salesItem.baseAmount"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                </v-col>

              
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-select
                  hide-details
                  style="font-size: 12px"
                  class="my-1"
                  label="Product*"
                  v-model="salesItem.productId"
                  :items="availableProducts"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-select
                  hide-details
                  :disabled="!salesItem.productId"
                  class="my-1"
                  style="font-size: 12px"
                  label="Variety*"
                  v-model="salesItem.varietyId"
                  :items="availableVarieties(salesItem.productId)"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field
                  hide-details
                  class="my-1"
                  label="Pack"
                  v-model="salesItem.packCode"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field
                  hide-details
                  class="my-1"
                  label="Grade"
                  v-model="salesItem.grade"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field
                  hide-details
                  class="my-1"
                  label="Count/Size"
                  v-model="salesItem.countSize"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field
                  hide-details
                  class="my-1"
                  label="Inventory Code"
                  v-model="salesItem.inventoryCode"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field
                  hide-details
                  class="my-1"
                  label="PUC"
                  v-model="salesItem.puc"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field
                  hide-details
                  class="my-1"
                  label="PHC"
                  v-model="salesItem.phc"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  v-model="salesItem.note"
                  outlined
                  rows="4"
                  auto-grow
                ></v-textarea>
               
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="currencyModal" width="400px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Select Currency</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="currencyModal = false" text>X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="searchCurrency"
            prepend-inner-icon="search"
            placeholder="Search Currency"
            outlined
            dense
          >
          </v-text-field>
          <v-list dense style="max-height: 60dvh; overflow-y: auto">
            <v-list-item v-if="filterCurrencies.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No available currencies</span
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(currency, index) in filterCurrencies"
              :key="index"
              @click="setBaseCurrency(currency)"
            >
              <v-list-item-action style="width: 100px">
                <v-chip small color="primary">
                  <h4 class="primaryText--text">{{ getSymbol(currency) }}</h4>
                </v-chip>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ currency }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AssignStock from "../Stock/AssignStock.vue";
import * as CurrencyCodes from "currency-codes";
import dateFormat from "dateformat";
import ProductSpecification from "./ProductSpecification.vue";
import getSymbolFromCurrency from "currency-symbol-map";
import OrderItemDetail from "./components/OrderItemDetail.vue";
export default {
  props: ["order", "containers", "loading", "stock", "related"],
  components: {
    AssignStock,
    OrderItemDetail,
    ProductSpecification,
  },
  data: () => ({
    createFactoredRate: false,
    assignStockModal: false,
    applyFactoredRate: false,
    cargoItem: {},
    cargoModal: false,
    cargoHeaders: [
      {
        text: "Actions",
        value: "actions",
        align: "center",
        width: "75px",
      },
      {
        text: `Quantity`,
        value: "quantity",
        align: "center",
        width: "100px",
      },
      {
        text: "Product",
        value: "product",
        align: "center",
        width: "200px",
      },
      {
        text: "Variety",
        value: "varietyProduct",
        align: "center",
        width: "250px",
      },
      {
        text: "Class/Grade",
        value: "grade",
        align: "center",
        width: "100px",
      },
      {
        text: "Brand/Mark",
        value: "mark",
        align: "center",
        width: "100px",
      },
      {
        text: "Pack",
        value: "packCode",
        align: "center",
        width: "100px",
      },

      {
        text: "Count/Size",
        align: "center",
        value: "countSize",
        width: "100px",
      },
      { text: "PHC", align: "center", value: "phc", width: "100px" },

      {
        text: "Inventory Code",
        value: "inventoryCode",
        align: "center",
        width: "100px",
      },
      {
        text: "Pallet Type",
        value: "palletType",
        align: "center",
        width: "100px",
      },
      // {
      //   text: "Currency",
      //   value: "currency",
      //   align: "center",
      //   width: "100px",
      // },
      // {
      //   text: "Unit Price",
      //   value: "unitPrice",
      //   align: "center",
      //   width: "100px",
      // },
    ],
    selectedCurrency: null,
    containerTypes: [],
    currencies: CurrencyCodes.codes(),
    currencyModal: false,
    errorSnackbar: false,
    loadingProduct: false,
    loadingCurrency: false,
    orderFinancialSetting: {},
    orderItem: {},
    orderItemModal: false,
    incoTerms: [],
    products: {
      total: 0,
      data: [],
    },
    orderSettings: [],
    orderItemStatus: [
      { name: "In Progress", icon: "pending", color: "blue" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Instruction Sent", icon: "email", color: "indigo" },
      { name: "Instruction Cancelled", icon: "warning", color: "orange" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Stuff Discrepancy", icon: "error", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },
    ],
    productKey: 500,
    productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    refreshKey: 100,
    savingSalesItem: false,
    salesItem: {},
    salesModal: false,
    savingOrderItem: false,
    searchCurrency: null,
    tradedProducts: [],
    tradedVariations: [],
    factoredRate: 1,
    currentExchangeRate: 1,
  }),
  watch: {
    "order.id": {
      handler: function (val) {
        if (val) {
          this.loadFinancialSettings(val);
        }
      },
      immediate: true,
    },
    "selectedCurrency": {
      handler: function(val) {
        if(val) {
          this.getCurrentRate(this.orderFinancialSetting.baseCurrency, this.selectedCurrency)
        }
      }
    }
  },
  async created() {
    this.containerTypes = await this.$API.listContainerTypes();
    this.incoTerms = await this.$API.getIncoTerms();
    this.loadProducts();
    this.getTradedProducts();
  },
  computed: {
    availableProducts() {
      let products = [].concat.apply(
        [],
        this.containers.map((x) =>
          x.orderCargoItems.filter((y) => y.product).map((y) => y.product)
        )
      );
      let uniqueProducts = [...new Set(products.map((x) => x.id))];
      let result = uniqueProducts.map((x) => products.find((y) => y.id == x));
      result.sort((a, b) => a.name.localeCompare(b.name));
      return result;
    },
    filterCurrencies() {
      let result = this.currencies;
      if (this.searchCurrency) {
        result = result.filter((currency) =>
          currency.toLowerCase().includes(this.searchCurrency.toLowerCase())
        );
      }
      return result;
    },
    filterProducts() {
      let result = this.products.data;
      result = result.filter((x) => x.type == "Product");
      return result;
    },
    filterVarieties() {
      let result = this.products.data;
      if (this.cargoItem && this.cargoItem.productId) {
        result = result.filter(
          (x) =>
            x.type == "Variety" && x.parentProductId == this.cargoItem.productId
        );
      } else {
        result = [];
      }
      return result;
    },
    validFactoredRate() {
      if(typeof this.factoredRate === 'number') {
        if(this.factoredRate > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    addCargoItem(
      item = {
        index: this.orderItem.orderCargoItems.length,
        tempId: new Date().getTime(),
      }
    ) {
      this.cargoItem = item;
      if (
        !this.cargoItem.quantity &&
        this.orderItem.orderCargoItems.length == 0
      ) {
        this.cargoItem.quantity = 100;
      }
      this.cargoModal = true;
    },
    addOrderItem(
      item = {
        quantity: 1,
        bookingCargoItems: [],
        containerTypeCode: "45R1",
        baseUnit: "CT",
        containerised: true,
        palletised: true,
        // index: this.containe.length,
        orderCargoItems: [],
        tempId: new Date().getTime(),
      }
    ) {
      if(localStorage.orderSettings){
        let data = JSON.parse(localStorage.orderSettings);
        item.palletised = data.palletised;
        item.containerised = data.containerised;
        item.baseUnit = data.baseUnit;
        item.containerTypeCode = data.containerTypeCode;
        item.unitPerPallet = data.unitPerPallet;
        item.palletPerContainer = data.palletPerContainer;
        item.unitPerContainer = data.unitPerContainer;
      } else {
        item.palletised = true;
        item.containerised = true;
        item.baseUnit = 'CT';
        item.containerTypeCode = '45R1';
        item.unitPerPallet = 80;
        item.unitPerContainer = 1600;
        item.palletPerContainer = 20;
      }
      this.orderItem = item;
      this.orderItemModal = true;
    },
    addLineItem(containerIndex = 1) {
      this.orderItem.orderCargoItems.push({
        quantity: 1,
        quantityType: "CT",
        tempId: new Date().getTime(),
        varietyId: null,
        grade: null,
        puc: null,
        countSize: null,
        packCode: null,
        mark: null,
        inventoryCode: null,
        cartons: 0,
        pallets: 0,
        container: 0,
        containerIndex,
        currency: 'USD',
        unitPrice: 0,
      });
    },
    addSalesItem(orderCargoItem) {
      this.salesItem = {
        type: "FIXED",
        orderId: this.order.id,
        bookingContainerId: orderCargoItem.bookingContainerId,
        orderItemId: this.orderItem.id,
        orderCargoItemId: orderCargoItem.id,
        orderCargoItem: orderCargoItem,
        unitQuantity: orderCargoItem.quantity,
        productId: orderCargoItem.productId,
        product: orderCargoItem.product,
        varietyId: orderCargoItem.varietyProductId,
        variety: orderCargoItem.varietyProduct,
        unitPrice: 0,
        total: 0,
        currency: this.orderFinancialSetting.baseCurrency,
        baseCurrency: this.orderFinancialSetting.baseCurrency,
        baseAmount: 0,
        baseTotal: 0,
        date: new Date().toISOString().split("T")[0],
        grade: orderCargoItem.grade,
        puc: orderCargoItem.puc,
        countSize: orderCargoItem.countSize,
        packCode: orderCargoItem.packCode,
        mark: orderCargoItem.mark,
        inventoryCode: orderCargoItem.inventoryCode,
        palletType: orderCargoItem.palletType,
        phc: orderCargoItem.phc,
      };
      this.salesModal = true;
    },
    assignStock() {
      this.assignStockModal = true;
      this.refreshKey++;
    },
    cloneItem(item) {
      let newItem = {
        ...item,
        quantity: 1,
      };
      delete newItem.id;
      delete newItem.createdAt;
      delete newItem.updatedAt;
      item.orderCargoItems.forEach((x) => {
        delete x.id;
        delete x.createdAt;
        delete x.updatedAt;
        x.orderProductSpecifications.forEach((y) => {
          delete y.id;
          delete y.orderCargoItemId;
          delete y.createdAt;
          delete y.updatedAt;
        });
      });
      this.addOrderItem(newItem);
    },
    async deleteItem(index, item) {
      if (!item.id) {
        let find = this.orderItem.orderCargoItems.findIndex(
          (x) => x.tempId == item.tempId
        );
        this.orderItem.orderCargoItems.splice(find, 1);
      } else {
        let find = this.orderItem.orderCargoItems.findIndex(
          (x) => x.id == item.id
        );
        this.orderItem.orderCargoItems.splice(find, 1);
        await this.$API.updateForecastLineItem({
          id: item.id,
          isActive: false,
          isDeleted: true,
        });
      }
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatNumber(x, isCost = false) {
      if(isCost){
        x *= (-1)
      }
      let result = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      
      return result
    },
    filteredValues(type, item = {}) {
      if (type == "packCode") {
        return this.tradedVariations
          .filter(
            (x) =>
              x.packCode && x.organisationProduct.productId == item.productId
          )
          .map((x) => ({
            name: x.packCode,
            value: x.packCode,
          }));
      } else if (type == "countSize") {
        let filtered = this.tradedVariations.filter(
          (x) =>
            x.countSize && x.organisationProduct.productId == item.productId
        );
        if (item.packCode) {
          filtered = filtered.filter((x) => x.packCode == item.packCode);
        }
        return filtered.map((x) => ({
          name: x.countSize,
          value: x.countSize,
        }));
      } else if (type == "variety") {
        let filtered = this.products.data.filter(
          (x) => x.parentProductId == item.productId && x.type == "Variety"
        );
        // let filtered = this.products.data.filter((x) => x.productId == item.productId && type == 'Variety');
        return filtered.map((x) => ({
          name: x.name,
          id: x.id,
        }));
      } else if (type == "palletType") {
        return [
          { name: "Standard", value: "S" },
          { name: "Chep", value: "C" },
          { name: "High Cube", value: "H" },
          { name: "Euro", value: "E" },
        ];
      } else {
        return this.orderSettings.filter(
          (x) => x.key === type && x.productId == item.productId
        );
      }
    },
    formatBaseUnit(type) {
      let result = this.productQuantityTypes.find((x) => x.value == type);
      return result ? result.text : "";
    },
    getSymbol(currencyCode) {
      return getSymbolFromCurrency(currencyCode);
    },
    getContainerDescription(code) {
      let result = null;
      if (code) {
        let findDescription = this.containerTypes.find(
          (x) => x.typeCode == code
        );
        result = findDescription ? findDescription.shortDescription : null;
      }
      return result;
    },
    async getTradedProducts() {
      this.tradedProducts = await this.$API.getTradedProducts();
      this.tradedVariations = await this.$API.getAllTradeVariations();
      this.orderSettings = await this.$API.getAllOrderSettings();
    },
    async loadProducts() {
      this.loadingProduct = true;
      this.products = await this.$API.getProducts({});
      this.loadingProduct = false;
    },
    async loadFinancialSettings(id) {
      this.orderFinancialSetting = await this.$API.getOrderFinancialSettings(
        id
      );
      this.selectedCurrency = this.orderFinancialSetting.baseCurrency
    },
    orderItemStatusColor(status) {
      if (status) {
        let color = this.orderItemStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else return "grey";
    },
    orderItemStatusIcon(status) {
      if (status) {
        let find = this.orderItemStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return "help";
    },
    availableVarieties(productId) {
      let products = [].concat.apply(
        [],
        this.containers.map((x) =>
          x.orderCargoItems
            .filter((y) => y.varietyProduct && y.productId == productId)
            .map((y) => y.varietyProduct)
        )
      );
      let uniqueProducts = [...new Set(products.map((x) => x.id))];
      let result = uniqueProducts.map((x) => products.find((y) => y.id == x));
      result.sort((a, b) => a.name.localeCompare(b.name));
      return result;
    },
    calculateTotal(units, price) {
      return Math.round(units * price * 100) / 100;
    },
    productSpec(item) {
      let keys = [
        { key: "puc", value: "PUC" },
        { key: "phc", value: "PHC" },
        { key: "packCode", value: "PACK" },
        { key: "grade", value: "Grade" },
        { key: "countSize", value: "Count" },
        { key: "inventoryCode", value: "Inventory Code" },
      ];
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (item[keys[i].key]) {
          result.push(keys[i].value + ": " + item[keys[i].key]);
        }
        // let find = item.orderProductSpecifications ?? [];
        // find = find.filter((x) => x.key == keys[i].key);
        // if (find.length > 0) {
        //   result.push(
        //     keys[i].value + ": " + find.map((x) => x.value).join(", ")
        //   );
        // }
      }
      return result.join(" - ");
    },
    async removeOrderItem(item, index = null) {
      if (item.id) {
        this.$confirm("Are you sure you want to delete this item?", "Confirm", {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }).then(async () => {
          let index = this.stock.findIndex((x) => x.id == item.id);
          let result = await this.$API.removeOrderContainer({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.$message.success("Successfully deleted item!");
          this.stock.splice(index, 1);
        });
      } else {
        // this.orderItem.orderCargoItems.splice(index, 1);
      }
    },
    removeCargoItem(item, index) {
      if (item.id) {
        this.$confirm(
          "Are you sure you want to delete this specification?",
          "Confirm",
          {
            center: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "plain",
          }
        ).then(async () => {
          let result = await this.$API.updateOrderItem({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.orderItem.orderCargoItems.splice(index, 1);
        });
      } else {
        this.orderItem.orderCargoItems.splice(index, 1);
      }
    },
    async saveLineItem(item) {
      if (item.id) {
        await this.$API.updateForecastLineItem(item);
      } else {
        let result = await this.$API.createForecastLineItem(item);
        item = { ...item, id: result.id };
      }
    },
    saveCargoItem(item) {
      let id = item.id ?? item.tempId;
      let findIndex = this.orderItem.orderCargoItems.findIndex(
        (x) => x.tempId == id || x.id == id
      );
      if (findIndex > -1) {
        this.orderItem.orderCargoItems[findIndex] = item;
      } else {
        this.orderItem.orderCargoItems.push(item);
      }
      this.cargoModal = false;
      this.cargoItem = {};
    },
    async saveOrderItem() {
      // let isValid =
      //   this.orderItem.orderCargoItems
      //     .map((x) => x.quantity)
      //     .reduce((a, b) => a + b, 0) == 100;
      // if (isValid) {
      this.savingOrderItem = true;
      try {
        localStorage.setItem('orderSettings', JSON.stringify({
          palletised: this.orderItem.palletised,
          containerised: this.orderItem.containerised,
          baseUnit: this.orderItem.baseUnit,
          containerTypeCode: this.orderItem.containerTypeCode,
          unitPerPallet: this.orderItem.unitPerPallet,
          palletPerContainer: this.orderItem.palletPerContainer,
          unitPerContainer: this.orderItem.unitPerContainer,
        }))
        if (this.orderItem.id) {
        let result = await this.$API.updateOrderItem(this.orderItem);
        this.$message.success("Successfully updated item!");
      } else {
        let result = await this.$API.createOrderItem(
          this.order.id,
          this.orderItem
        );
        this.$message.success("Successfully created item!");
        this.$emit("refreshStock");
      }
      this.savingOrderItem = false;
      this.orderItemModal = false;
      this.orderItem = {};
      } catch(e){
        this.$message.error("Error saving item")
        this.savingOrderItem = false
      }
     
      // } else {
      //   this.errorSnackbar = true;
      // }
    },
    async saveItem(item) {
      // this.saving = true;
      await this.$API.updateOrderItem({
        id: item.id,
        stockStatus: item.stockStatus,
      });
      // this.saving = false;
      // this.itemModal = false;
      this.$message.success("Order Item Updated");
      // this.getOrders();
    },
    async saveSalesItem() {
      this.savingSalesItem = true;
      this.salesItem.total = this.calculateTotal(
        this.salesItem.unitQuantity,
        this.salesItem.unitPrice
      );
      if(this.salesItem.baseCurrency == this.salesItem.currency){
        this.salesItem.baseAmount = this.salesItem.unitPrice
      }
      this.salesItem.baseTotal = this.calculateTotal(
        this.salesItem.unitQuantity,
        this.salesItem.baseAmount
      );
      this.salesItem.exchangeRate = this.salesItem.total / this.salesItem.baseTotal;
     
      if(!this.salesItem.id){
        let result = await this.$API.createOrderSalesItems(this.salesItem);
        this.orderItem.orderCargoItems.find(x => x.id == this.salesItem.orderCargoItemId).orderSalesItems.push(result)
      } else {

        let result = await this.$API.updateOrderSalesItems(this.salesItem);
        
      }
     
      this.salesModal = false;
      this.savingSalesItem = false;
    },
    setProduct(e, data) {
      let product = this.products.data.find((x) => x.id == e);
      data.product = product;
      data.varietyProduct = null;
      data.varietyProductId = null;
      this.productKey++;
      this.saveLineItem(data);
    },
    setVariety(e, data) {
      data.varietyProductId = e;
      if(e){
        let find = this.products.data.find((x) => x.id == e);
      data.varietyProduct = find;
      } else {
        data.varietyProduct = null;
      }      
      this.saveLineItem(data);
    },
    async saveOrderFinancialSetting() {
      this.loading = true;
      await this.$API.updateOrderFinancialSettings(this.orderFinancialSetting);
      this.loading = false;
    },
    async setBaseCurrency(currency) {
      this.orderFinancialSetting.baseCurrency = currency;
      this.currencyModal = false;
      this.saveOrderFinancialSetting();
    },
    async getCurrentRate(currencyFrom, currencyTo) {
      this.loadingCurrency = true
      let rate = `${currencyFrom}/${currencyTo}`
      // let rate = `USD/ZAR`
      // console.log('Getting current rate')
      if(currencyFrom === currencyTo) {
        this.currentExchangeRate = 1
      } else {
        let res = await this.$API.getLatestExchangeRate({rate: rate})
        this.currentExchangeRate = parseFloat(res.rate.toFixed(4))
      }

      // if(this.appliedLiveExchangeRate) {
      //   this.lineItem.exchangeRate = this.currentExchangeRate
      //   this.appliedExchangeRate = this.inverseCurrentRate
      // }
      // this.lineItem.baseCost = parseFloat((this.lineItem.unitCost * this.lineItem.exchangeRate).toFixed(this.decimalSecondary))
      // if(this.lineItem.currency !== this.lineItem.baseCurrency) {
      //       this.revertedSalePrice = parseFloat((this.lineItem.baseSale / this.lineItem.exchangeRate).toFixed(this.decimalSecondary))
      //       // this.revertedSalePrice = parseFloat((this.lineItem.baseSale * this.appliedExchangeRate).toFixed(2))
      // }
      this.loadingCurrency = false
    },
    applyCurrentRate() {
      this.factoredRate = parseFloat(this.currentExchangeRate)
    },
    closeApply() {
      this.applyFactoredRate = false
    }
  },
};
</script>
<style>
.v-chip .v-chip__content {
  width: 100%;
}
.v-input__slot {
  padding: 0;
}
</style>